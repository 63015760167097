import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./cart.css";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";



const CartPage = () => {

  const lan =localStorage.getItem('i18nextLng'); // Store selected language

  const { t } = useTranslation();
  // const lan =localStorage.getItem('i18nextLng');
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleView = (productId) => {
    if (!productId) {
      console.error("Product ID is not available.");
      return;
    }
  
    // Navigate to the viewproduct page with the product ID
    navigate(`/viewproduct`, { state: { productId } });
  };
  

  useEffect(() => {
    fetchCartItems();
  }, []);

  useEffect(() => {
    calculateTotalPrice(products); // Recalculate total whenever products change
  }, [products]);

  const fetchCartItems = async (language = localStorage.getItem('i18nextLng')) => {
    try {
      const userId = localStorage.getItem("user_id");
      const apiKey = localStorage.getItem("user_api_key");

      if (!userId || !apiKey) {
        setError("User ID or API Key not found");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/viewcart",
        {
          language,
        },
        {
          headers: {
            "Content-Type": "application/json",
            api_key: apiKey,
            user_id: userId,
          },
        }
      );

      if (response.data && response.data.data) {
        const cartItems = response.data.data.map((item) => ({
          product_id: item.product_id,
          name: item.product_name,
          quantity: item.quantity,
          price: item.price,
          unit:item.unit,
        }));

        // Store the formatted cart items in localStorage
        // localStorage.setItem("cart_items", JSON.stringify(cartItems));

        setProducts(response.data.data);
      } else {
        setError("No products added to cart ");
      }
    } catch (error) {
      setError("Error fetching cart items: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalPrice = (products) => {
    const total = products.reduce(
      (sum, product) =>
        sum + parseInt(product.price) * parseInt(product.quantity),
      0
    );
    setTotalPrice(total);
    localStorage.setItem("total_price", total); // Store the total price in localStorage
  };

  const handleRemoveProduct = async (cartId) => {
    try {
      const userId = localStorage.getItem("user_id");
      const userApiKey = localStorage.getItem("user_api_key");

      if (!userId || !userApiKey) {
        setError("User ID or API Key not found");
        return;
      }

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/delete/cart",
        {
          user_id: userId,
          cart_id: cartId,
        },
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
          },
        }
      );

      if (response.data && response.data.result === true) {
        console.log("Product deleted successfully.");
        fetchCartItems(); // Refresh the cart
      } else {
        console.error("Failed to delete product:", response.data.message);
      }
    } catch (error) {
      setError("Error deleting product: " + error.message);
    }
  };

  const handleCheckout = () => {
    const checkoutData = {
      products: products.map((product) => ({
        productId: product?.product_id,
        productName: product?.product_name,
        price: product?.price,
        quantity: product?.quantity,
        unit:product?.unit
      })),
      totalPrice: totalPrice, // Pass total price as well
    };

    console.log("Checkout Data:", products); // Log the data being passed

    navigate("/buynow", {
      state: {
        products: checkoutData.products,
        totalPrice: checkoutData.totalPrice,
      },
    });
  };
  

  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  


  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div>
      <Container>
        <Row className="mt-4">
          <Col md={12}>
            <h3 style={{direction}}>  {t('YOUR CART')}</h3>
            {products?.length > 0 ? (
              products.map((product) => (
                <Card key={product.product_id} className="mb-3">
                  <Card.Body>
                    <Row>
                      <Col md={2}>
                        <img
                          src={`https://lunarsenterprises.com:3000/${product.image_file}`}
                          alt={product.product_name}
                          className="img-fluid"
                          onClick={() => handleView(product.product_id)}
                        />
                      </Col>
                      <Col md={6}>
                        <h5 onClick={() => handleView(product.product_id)}>{product.product_name}</h5>
                        <p>  {t('PRICE')}: ₹{product.price}</p>
                        <p> {t('QUANTITY')}: {product.quantity}</p>
                      </Col>
                      <Col
                        md={4}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveProduct(product.cart_id)}
                        >
                          {t('REMOVE')}
                          
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p className="cart-no-products">No products in the cart</p>
            )}
            <div
              className="mt-3 d-flex align-items-center justify-content-between shadow p-3"
              style={{
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
              }}
            >
              <h4 style={{ margin: 0 }}> {t('TOTAL PRICE')} ₹{totalPrice}</h4>
             
                <Button className="buyin-button" onClick={handleCheckout}>
                {t('CHECKOUT')}
                 
                </Button>
           
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CartPage;
