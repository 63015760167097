import React, { useState, useEffect } from "react";
import {
  Carousel,
  Button,
  ButtonGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./viewproducts.css";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function ViewProduct() {
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const location = useLocation();
  const { productId } = location.state || {};
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [products, setProducts] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userApiKey, setUserApiKey] = useState(null);
  const { t } = useTranslation();
  const lan =localStorage.getItem('i18nextLng');

 

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    const storedUserApiKey = localStorage.getItem("user_api_key");

    if (storedUserId) {
      setUserId(storedUserId);
    }

    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  const [price, setprice] = useState();

  useEffect(() => {
    const fetchProductDetails = async (language = localStorage.getItem('i18nextLng')) => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/productview",
          {
            product_id: [productId],language
          }
        );
        const data = response.data; // Assigning the response data to a variable
               setProduct(data.data); // Ensure product details are set
        setprice(data.data?.[0]?.price || 0); // Extract the product price
        console.log("Fetched product details:", data.data);
        console.log("Price state updated:", data); // Logging a clear message with the updated data
        // Check the API response

        if (data.result) {
          setProduct(data.data); // Make sure data is being set correctly
        } else {
          console.error("Failed to retrieve product details");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [productId]);
// console.log(product[0]?.stock_stock,"11111");

  const increment = () => setQuantity((prevQuantity) => prevQuantity + 1);
  const decrement = () => {
    if (quantity > 1) setQuantity((prevQuantity) => prevQuantity - 1);
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleWishlistClick = async (product_id, currentWishlistStatus, language = localStorage.getItem('i18nextLng')) => {
    try {
      // Determine the new wishlist status
      const newWishlistStatus = currentWishlistStatus ? 0 : 1;

      const response = await axios.post(
        {
          wish: newWishlistStatus, // Toggle wishlist status
          product_id,
          language
          
        },
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
          },
        }
      );

      if (response.data.result) {
        // Update the product state locally
        setProducts((prevProducts) =>
          prevProducts?.map((product) =>
            product.product_id === product_id
              ? { ...product, isInWishlist: newWishlistStatus }
              : product
          )
        );

        if (newWishlistStatus) {
          alert("Product added to wishlist.");
        } else {
          alert("Product removed from wishlist.");
        }
      } else {
        console.error("Failed to update wishlist:", response.data.message);
        alert("Please log in to manage your wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      alert("An error occurred while updating the wishlist. Please try again.");
    }
  };

  var handleAddToCart = async (product_id, currentCartStatus,language = localStorage.getItem('i18nextLng')) => {
    try {
      // Ensure userId and userApiKey are available
      if (!userId || !userApiKey) {
        console.error("User is not authenticated");
        alert("Please log in to manage your cart.");
        return;
      }

      // Determine the new cart status
      const newCartStatus = currentCartStatus ? 0 : 1;

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/addcart",
        {
          cart: newCartStatus, // Toggle cart status
          product_id,language
        },
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
          },
        }
      );
      console.log(response.data, "hiiiyyyyy");

      if (response.data.result) {
        // Update the product state locally
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.product_id === product_id
              ? { ...product, isInCart: newCartStatus }
              : product
          )
        );

        if (newCartStatus) {
          alert("Product added to cart.");
        } else {
          alert("Product removed from cart.");
        }
      } else {
        console.error("Failed to update cart:", response.data.message);
        alert(response.data.message || "Failed to update cart.");
      }
    } catch (error) {
      console.error("Error updating cart:", error);
      alert("An error occurred while updating the cart. Please try again.");
    }
  };




  
  const handleBuyNow = () => {
    if (!product || product.length === 0) {
      console.error("Product data is not available");
      alert("Product data is missing. Please try again.");
      return;
    }
  
    navigate("/buynow", {
      state: {
        productlist: product?.[0]?.product_id,
        productName: product?.[0]?.product_name,
        price: product?.[0]?.price,
        quantity: quantity,
        product_unit:product?.[0]?.product_unit

      },
    });
  };
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  

  return (
    <div className="container view-product-container">
      {product.map((product, productIndex) => (
        <div key={productIndex} className="row mb-4 align-items-center">
          {/* Left Column: Carousel */}
          <div className="col-md-6">
            <Carousel
              className="product-carousel"
              controls={false}
              activeIndex={carouselIndex}
              onSelect={(selectedIndex) => setCarouselIndex(selectedIndex)}
            >
              {product.product_image.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 products-image"
                    src={`https://lunarsenterprises.com:3000/${image.image_file}`}
                    alt={`Product Image ${index + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>

          {/* Right Column: Product Details */}
          <div className="col-md-6 mt-5">
            <div className="viewproduct-details p-3">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="product-name mb-0">{product.product_name}</h4>

                <button
                  className="button-wishlist"
                  onClick={(e) => {
                    e.preventDefault();
                    handleWishlistClick(
                      product.product_id,
                      product.isInWishlist
                    );
                  }}
                >
                  <FontAwesomeIcon className="home-heart-icon" icon={faHeart} />
                </button>
              </div>
              <hr className="my-2" />
              <p className="product-details mb-1">
                {product.description.split("\r\n").map((line, index) => (
                  <span key={index} className="line">
                    {line}
                  </span>
                ))}
              </p>

              <h4 className="product-price mb-3">Rs {product.price}.00</h4>

              {/* Quantity Selector */}
              <div className="d-flex align-items-center mt-3">
                <InputGroup className="quantity-selector">
                  <ButtonGroup>
                    <Button variant="outline-dark" onClick={decrement}
                >
                      -
                    </Button>
                  </ButtonGroup>
                  <FormControl
                    value={quantity}
                    readOnly
                    className="text-center"
                    style={{ maxWidth: "60px" }}
                  />
                  <ButtonGroup>
                    <Button variant="outline-dark" onClick={increment}
                disabled={quantity === product?.stock_stock}
                >
                      +
                    </Button>
                  </ButtonGroup>
                </InputGroup>
              </div>

              {/* Buying Options */}
              <div className="buying-options mt-4">
                <div>
                  <Button
                    onClick={handleBuyNow}
                    className="buying-button w-100 mb-2"
                    style={{direction}}
                        >
                         {t('BUY NOW')}
                  </Button>
                </div>
                <Link to="/addtocart">
                  <Button
                    className="buyin-button w-100"
                   
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default link behavior
                      handleAddToCart(product.product_id, product.isInCart);
                    }}
                    style={{direction}}
                    >
                   {t('ADD TO CART')}
             
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ViewProduct;
