import React, { useEffect, useState } from "react";
import "./product.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faHeart } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function Products() {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const categoryIds = location.state?.categoryId || {}; // Get categoryId from location state
  const [userId, setUserId] = useState(null);
  const [userApiKey, setUserApiKey] = useState(null);
   const lan =localStorage.getItem('i18nextLng');





   const userIds = localStorage.getItem("user_id"); // Assuming the user ID is saved as 'user_id' in localStorage
   const apikey = localStorage.getItem("user_api_key"); // Assuming the user ID is saved as 'user_id' in localStorage

  useEffect(() => {
    const userId = localStorage.getItem("user_id"); // Assuming the user ID is saved as 'user_id' in localStorage
    const apikey = localStorage.getItem("user_api_key"); // Assuming the user ID is saved as 'user_id' in localStorage

    if (userId) {
      setUserId(userId);
    }

    if (apikey) {
      setUserApiKey(apikey);
    }
  }, []);
console.log(categoryIds,"1111")
  useEffect(() => {
    const fetchProducts = async (language = localStorage.getItem('i18nextLng')) => {
      try {
        // const categoryId = location.state?.categoryId || null;
// console.log(categoryId,"22222")

        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/productlist",
          { category_id: categoryIds,language }
        );

        const data = response.data;
console.log(data,"777777")
        
        if (data.result) {
          setProducts(data.data); // Set the fetched products to state
        } else {
          console.error("Failed to retrieve data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProducts();
  }, [categoryIds, location.state?.categoryId]);

  // Handle Wishlist functionality
  const handleWishlistClick = async (product_id, currentWishlistStatus,language = localStorage.getItem('i18nextLng')) => {
    try {
      // Determine the new wishlist status
      const newWishlistStatus = currentWishlistStatus ? 0 : 1;

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/wishlist",language,
        {
          wish: newWishlistStatus, // Toggle wishlist status
          product_id,
        },
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
          },
        }
      );

      if (response.data.result) {
        // Update the product state locally
        setProducts((prevProducts) =>
          prevProducts?.map((product) =>
            product.product_id === product_id
              ? { ...product, isInWishlist: newWishlistStatus }
              : product
          )
        );

        if (newWishlistStatus) {
          alert("Product added to wishlist.");
        } else {
          alert("Product removed from wishlist.");
        }
      } else {
        console.error("Failed to update wishlist:", response.data.message);
        alert("Please log in to manage your wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      alert("An error occurred while updating the wishlist. Please try again.");
    }
  };

  // Handle Add to Cart functionality
  const handleAddToCart = async (product_id, currentCartStatus,language = localStorage.getItem('i18nextLng')) => {
    try {
      // Ensure userId and userApiKey are available
      if (!userId || !userApiKey) {
        console.error("User is not authenticated");
        alert("Please log in to manage your cart.");
        return;
      }

      // Determine the new cart status
      const newCartStatus = currentCartStatus ? 0 : 1;

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/addcart",
        {
          cart: newCartStatus, // Toggle cart status
          product_id,
        },
        {
          headers: {
            user_id: userIds,
            api_key: apikey,
          },
        }
      );console.log(response.data,"hiiiyyyyy");
      

      if (response.data.result) {
        // Update the product state locally
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.product_id === product_id
              ? { ...product, isInCart: newCartStatus }
              : product
          )
        );

        if (newCartStatus) {
          alert("Product added to cart.");
        } else {
          alert("Product removed from cart.");
        }
      } else {
        console.error("Failed to update cart:", response.data.message);
        alert(response.data.message || "Failed to update cart.");
      }
    } catch (error) {
      console.error("Error updating cart:", error);
      alert("An error occurred while updating the cart. Please try again.");
    }
  };

  // Handle Buy Now functionality
  const handleDivClick = (productId) => {
    const userApiKey = localStorage.getItem("user_api_key");
    const userId = localStorage.getItem("user_id");

    if (!userApiKey || !userId) {
      // Navigate to the login page if user_api_key or user_id is null
      navigate("/login");
    } else {
    navigate(`/viewproduct`, { state: { productId, userId } });
  } // Pass user_id to view product page
  };
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <div>
      <section className="ezy__epgrid5 light">
        <div className="container">
          {/* Row start */}
          <div className="row ezy__epgrid5-row text-center justify-content-xl-center mt-2">
            {/* Map through products */}
            {products.map((product) => (
              <div
                key={product.product_id}
                className="col-md-6 col-lg-4 col-xl-3 px-2 my-3"
              >
                <div className="card ezy__epgrid5-card border-0 p-2">
                  <div className="card-body ezy__epgrid5-card-img position-relative h-100">
                    {/* Cart Icon */}
                    <a
                      href="#!"
                      className="ezy__epgrid5-cart-icon"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior
                        handleAddToCart(product.product_id, product.isInCart);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCartShopping}
                        style={{
                          color: product.isInCart ? "green" : "gray",
                        }}
                      />
                    </a>

                    {/* Wishlist Icon */}

                    <a
                      href="#!"
                      className="ezy__epgrid5-fav-icon"
                      onClick={(e) => {
                        e.preventDefault();
                        handleWishlistClick(
                          product.product_id,
                          product.isInWishlist
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHeart}
                        style={{
                          color: product.isInWishlist ? "red" : "gray",
                        }}
                      />
                    </a>

                    <div className="ezy__epgrid5-card-img-wrapper">
                      <a href="#!">
                        <img
                          src={`https://lunarsenterprises.com:3000/${product.image_file}`}
                          alt={product.product_name}
                          className="ezy__epgrid5-card-img-full"
                          onClick={() => handleDivClick(product.product_id)}
                        />
                      </a>
                    </div>
                  </div>

                  <div>
                    <h5 className="ezy__epgrid5-title">
                      {product.product_name}
                    </h5>
                    <h5 className="ezy__epgrid5-price my-2">
                      Rs.{product.price}.00
                    </h5>
                    <p className="ezy__epgrid5-quantity">
                      {product.quantity} {product.product_unit}
                    </p>
                    <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
                      <Button
                        variant="primary"
                        className="buy-now w-100"
                        onClick={() => handleDivClick(product.product_id)} // Pass user_id when triggering "Buy Now"
                        style={{direction}}
                        >
                         {t('BUY NOW')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="navigation-buttons">
            <Button variant="outline-dark" className="nav-btn previous">
              &#8249;
            </Button>
            <Button variant="outline-dark" className="nav-btn next">
              &#8250;
            </Button>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Products;
