import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import axios from 'axios';
import './change.css';
import { useLocation, useNavigate } from 'react-router-dom';


const PasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate=useNavigate()

  const location = useLocation();
  const { email } = location.state || {};
  
  console.log(email); 
  

  
  const handleSave = async (e) => {
    e.preventDefault();

    
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    
    setError('');
    setLoading(true);

    const data = {
      email: email, 
      password: password, 
    };

    try {

      const response = await axios.post('https://lunarsenterprises.com:3000/bhakshanangal/change-password', data);

      if (response.data) {
        setSuccessMessage('Password changed successfully!');
        navigate("/login")
        setPassword('');
        setConfirmPassword('');
      } else {
        setError('Failed to change the password. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again later.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const handleCancel = () => {
    setPassword('');
    setConfirmPassword('');
    setError('');
    setSuccessMessage('');
  };

 
  if (!email) {
    return <Alert variant="danger">Email is missing. Please go back and try again.</Alert>;
  }

  return (
    <Container className="password-form-container">
      <div className="password-form-box">
        <h4 className="form-title">CHANGE PASSWORD</h4>
        <Form onSubmit={handleSave}>

          
          <Form.Group controlId="password">
            <Form.Label className='password-label'>New Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your new password"
              className="form-input"
              required
            />
          </Form.Group>

        
          <Form.Group controlId="confirmPassword">
            <Form.Label className='password-label'>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
              className="form-input"
              required
            />
          </Form.Group>

        
          {error && <p className="error-message">{error}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}

          <div className="form-buttons">
            
            <Button
              variant="primary"
              className="change-password-savebutton"
              type="submit" 
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>

          
            <Button
              variant="outline-secondary"
              className="change-password-cancelbutton"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default PasswordForm;
