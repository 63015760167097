import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      "Login here":"Login here",
      "Already have an account?":"Already have an account?",
      "Enter Confirm Password":"Enter Confirm Password",
      "Confirm Password":"Confirm Password",
      "Enter Your Name":"Enter Your Name",
      "Create account":"Create account",
      "Don't have an account?":"Don't have an account?",
      "BEST SELLING PRODUCTS": "BEST SELLING PRODUCTS",
      "NEW ARRIVALS": "NEW ARRIVALS",
      "OUR PRODUCTS": "OUR PRODUCTS",
      "BUY NOW": "BUY NOW",
      "QUICK LINKS": "QUICK LINKS",
      " PRIVACY AND POLICY": "PRIVACY AND POLICY",
      "TERMS AND USE": "TERMS AND USE",
      "FAQ": "FAQ",
      "CONTACT US": "CONTACT US",
      "ABOUT US": "ABOUT US",
      "KEEP IN TOUCH": "KEEP IN TOUCH",
      "BUILDING A2 IFZA, BUSINESS PARK,": "Building A2 IFZA, Business Park",
      "DUBAI SILICON OASIS, DUBAI": "DUBAI SILICON OASIS, DUBAI",
      "MG CORPORATE CENTRE": "MG CORPORATE CENTRE",
      "NEAR IOC PUMP, ULLOOR JUNCTION, MCPO, TVM, KERALA 695011":
        "NEAR IOC PUMP, ULLOOR JUNCTION, MCPO, TVM, KERALA 695011",
      "ADD TO CART": "ADD TO CART",
      "MOVE TO BAG":"MOVE TO BAG",
      "DELIVERY TO":"DELIVERY TO",
      "SHIPPPING ADDRESS":"SHIPPPING ADDRESS",
      "SELECT A SAVED ADDRESS":"SELECT A SAVED ADDRESS",
      "CHOOSE AN ADDRESS":"CHOOSE AN ADDRESS",
      "SELECT PAYMENT OPTION":"SELECT PAYMENT OPTION",
      "ONLINE PAYMENT":"ONLINE PAYMENT",
      "CASH ON DELIVERY":"CASH ON DELIVERY",
      "PAYMENT MODE":"PAYMENT MODE",
      "ORDER SUMMARY":"ORDER SUMMARY",
      "TOTAL":'TOTAL',
      "PHONE NUMBER":"PHONE NUMBER",
      "PINCODE":"PINCODE",
      "STATE":"STATE",
      "ADD NEW ADDRESS":"ADD NEW ADDRESS",
      "Select Language": "Select Language",
      "FULL NAME":"FULL NAME",
      "ALTERNATIVE PHONE NUMBER":"ALTERNATIVE PHONE NUMBER",
      "CITY":"CITY",
      "BUILDING NAME":"BUILDING NAME",
      "AREA NAME":"AREA NAME",
      "LANDMARK":"LANDMARK",
      "SAVE ADDRESS":"SAVE ADDRESS",
      "CANCEL":"CANCEL",
      "LOGIN":"LOGIN",
      "&copy; 2024 Bhakshanangal Foods Private Ltd. All Rights Reserved.":"&copy; 2024 Bhakshanangal Foods Private Ltd. All Rights Reserved.",
      "YOUR CART":"YOUR CART",
      "WELCOME TO BHAKSHANANGAL!":"WELCOME TO BHAKSHANANGAL!",
      "Our Mission":"Our Mission",
      "QUANTITY":"QUANTITY",
      "PRICE":"PRICE",
      "REMOVE":"REMOVE",
      "TOTAL PRICE":"TOTAL PRICE",
      "CHECKOUT":"CHECKOUT",
      "LOGOUT":"LOGOUT",
      'Email Address':'Email Address',
      "ALL":"ALL",
      "DISTRICT":"DISTRICT",
      "Password":"Password",
      "Remember me": "Remember me",
      "Don't have an account?":"Don't have an account?",
      "Forget your password?":"Forget your password?",
      "Enter Email Address": "Enter Email Address",
      "Enter Password":"Enter Password",
      "Name":"Name",
      English: "English",
      Malayalam: "Malayalam",
      Hindi: "Hindi",
      // Tamil: "Tamil",
      Arabic: "Arabic",
    },
  },
  ml: {
    translation: {
      "Login here":"ഇവിടെ ലോഗിൻ ചെയ്യുക",
      "Already have an account?":"ഇതിനകം ഒരു അക്കൗണ്ട് ഉണ്ടോ?",
      "Enter Confirm Password":"പാസ്‌വേഡ് സ്ഥിരീകരിക്കുക",
      "Confirm Password":"പാസ്‌വേഡ് സ്ഥിരീകരിക്കുക",
      "Name":"പേര്",
      "Enter Your Name":"നിങ്ങളുടെ പേര് നൽകുക",
      "Enter Email Address": "ഇമെയിൽ വിലാസം നൽകുക",
      "Create account":"അക്കൗണ്ട് സൃഷ്ടിക്കുക",
      "Don't have an account?":"അക്കൗണ്ട് ഇല്ലേ?",
      "BEST SELLING PRODUCTS": "ഞങ്ങളുടെ ഏറ്റവും വിൽപ്പനയുള്ള ഉൽപ്പന്നങ്ങൾ",
      "NEW ARRIVALS": "പുതിയ ഉല്പന്നങ്ങൾ",
      "OUR PRODUCTS": "ഞങ്ങളുടെ ഉൽപ്പന്നങ്ങൾ",
      "Select Language": "ഭാഷ തിരഞ്ഞെടുക്കുക",
      "BUY NOW": "വാങ്ങുക",
      "QUICK LINKS": "ത്വരിത ലിങ്കുകൾ",
      "PRIVACY AND POLICY": "ഗോപനീയതയും നയവും",
      "TERMS AND USE": "നിബന്ധനകളും ഉപയോഗവും",
      "FAQ": "പതിവുചോദ്യങ്ങൾ",
      "CONTACT US": "ഞങ്ങളെ സമീപിക്കുക",
      "ABOUT US": "ഞങ്ങളേക്കുറിച്ച്",
      "KEEP IN TOUCH": "ഞങ്ങളെ സമീപിക്കുക",
      "BUILDING A2 IFZA, BUSINESS PARK,": "കെട്ടിടം A2 IFZA, ബിസിനസ് പാർക്ക്,",
      "DUBAI SILICON OASIS, DUBAI": "ദുബായ് സിലിക്കൺ ഒയാസിസ്, ദുബായ്",
      "MG CORPORATE CENTRE": "എംജി കോർപ്പറേറ്റ് സെൻ്റർ",
      "NEAR IOC PUMP, ULLOOR JUNCTION, MCPO, TVM, KERALA 695011":
        "ഐഒസി പമ്പിന് സമീപം, ഉള്ളൂർ ജംഗ്ഷൻ, എംസിപിഒ, ടിവിഎം, കേരളം",
      "ADD TO CART": "കാർട്ടിലേക്ക് ചേർക്കുക",
      "MOVE TO BAG":"ബാഗിലേക്ക് മാറ്റുക",
      "DELIVERY TO":"ഡെലിവറി",
      "SHIPPPING ADDRESS":"ഷിപ്പിംഗ് വിലാസം",
      "SELECT A SAVED ADDRESS":"സംരക്ഷിച്ച ഒരു വിലാസം തിരഞ്ഞെടുക്കുക",
      "CHOOSE AN ADDRESS":"ഒരു വിലാസം തിരഞ്ഞെടുക്കുക",
      "SELECT PAYMENT OPTION":"പേയ്‌മെൻ്റ് ഓപ്ഷൻ തിരഞ്ഞെടുക്കുക",
      "ONLINE PAYMENT":"ഓൺലൈൻ പേമെന്റ്",
      "CASH ON DELIVERY":"പണം കൈമാറ്റം",
      "PAYMENT MODE":"പണമിടപാട് മോഡ്",
      "ORDER SUMMARY":"ഓർഡർ സമാരംഭം",
      "ADD NEW ADDRESS":"പുതിയ വിലാസം ചേർക്കുക",
      "FULL NAME":"മുഴുവൻ പേര്",
      "PHONE NUMBER":"ഫോൺ നമ്പർ",
      "PINCODE":"പിന്‍ കോഡ്",
      "STATE":"സംസ്ഥാനം",
      "TOTAL":'ആകെ',
      "ALTERNATIVE PHONE NUMBER":"പരിഗണിക്കാവുന്ന ഫോൺ നമ്പർ",
      "CITY":"നഗരം",
      "Remember me": "എന്നെ ഓർമിപ്പിക്കുക",
      "BUILDING NAME":" ബിൽഡിംഗ് നെയിം",
      "AREA NAME":"പ്രദേശത്തിന്റെ പേര്",
      "LANDMARK":"ഭൂമിശാസ്ത്ര ചിഹ്നം ",
      "SAVE ADDRESS":"അഡ്രസ്സ് സേവ് ചെയ്യുക",
      "CANCEL":"റദ്ദാക്കുക",
      "WELCOME TO BHAKSHANANGAL!":"ബക്ഷംഗലിലേക്ക് സ്വാഗതം!",
      "LOGIN":"ലോഗിൻ",
      "REGISTER":"രജിസ്റ്റർ ചെയ്യുക",
      "&copy; 2024 Bhakshanangal Foods Private Ltd. All Rights Reserved.":"&പകർപ്പ്; 2024 ഭക്ഷണങ്ങൾ ഫുഡ്സ് പ്രൈവറ്റ് ലിമിറ്റഡ്. എല്ലാ അവകാശങ്ങളും നിക്ഷിപ്തം.",
      "Our Mission":"ഞങ്ങളുടെ ദൗത്യം",
      "YOUR CART":"നിങ്ങളുടെ ഓർഡർ",
      "QUANTITY":"അളവ്",
      "PRICE":"വില",
      "REMOVE":"നീക്കം ചെയ്യുക",
      "TOTAL PRICE":"ആകെ വില",
      "CHECKOUT":"ചെക്ക് ഔട്ട്",
      "LOGOUT":"പുറത്തുകടക്കുക",
      "ALL":"എല്ലാം",
      "DISTRICT":"ജില്ല",
      'Email Address':'ഇമെയിൽ',
      "Password":" പാസ്‌വേഡ് ",
      "Forget your password?":"നിങ്ങളുടെ പാസ്‌വേഡ് മറന്നോ?",
      "Enter Password":"പാസ്വേഡ് നൽകുക",
      English: "English",
      Malayalam: "മലയാളം",
      Hindi: "हिंदी",
      // Tamil: "தமிழ்",
      Arabic: "عربي",
    },
  },

  ar: {
    translation: {
      "Login here":"قم بتسجيل الدخول هنا",
      "Already have an account?":"هل لديك حساب بالفعل؟",
      "Enter Confirm Password":"تأكيد كلمة المرور",
      "Confirm Password":"تأكيد كلمة المرور",
      "Name":"اسم",
      "Enter Your Name":"أدخل اسمك",
      "Remember me": "تذكرني",
      "Enter Password":"أدخل كلمة المرور",
      "Enter Email Address": "أدخل عنوان البريد الإلكتروني",
      "Create account":"إنشاء حساب ",
      "Don't have an account?":"ليس لديك حساب؟",
      "BEST SELLING PRODUCTS": "أفضل المنتجات مبيعًا",
      "NEW ARRIVALS": "الصولات الجديدة",
      "OUR PRODUCTS": "منتجاتنا",
      "BUY NOW": "اشترِ الآن",
      "PRIVACY AND POLICY": "لخصوصية والسياسة",
      "Select Language": "اختر اللغة",
      "QUICK LINKS": "روابط سريعة",
      "TERMS AND USE": "الشروط والاستخدام",
      "FAQ": "التعليمات",
      "CONTACT US": "اتصل بنا",
      "ABOUT US": "معلومات عنا",
      "KEEP IN TOUCH": "أبق على اتصال",
      "BUILDING A2 IFZA, BUSINESS PARK,": "المبنى A2 IFZA، مجمع الأعمال",
      "DUBAI SILICON OASIS, DUBAI": "واحة دبي للسيليكون، دبي",
      "MG CORPORATE CENTRE": "مركز شركة إم جي",
      "NEAR IOC PUMP, ULLOOR JUNCTION, MCPO, TVM, KERALA 695011":"بالقرب من مضخة اللجنة الأولمبية الدولية، تقاطع أولور، MCPO، TVM، كيرالا",
      "ADD TO CART": "أضف إلى السلة",
      "MOVE TO BAG":"الانتقال إلى الحقيبة",
      "DELIVERY TO":"التسليم إلى",
      "SHIPPPING ADDRESS":"عنوان الشحن",
      "SELECT A SAVED ADDRESS":"حدد عنوانًا محفوظًا",
      "CHOOSE AN ADDRESS":"اختر عنوانا",
      "SELECT PAYMENT OPTION":"حدد خيار الدفع",
      "ONLINE PAYMENT":"الدفع عبر الإنترنت",
      "CASH ON DELIVERY":"الدفع عند الاستلام",
      "PAYMENT MODE":"وضع الدفع",
      "ORDER SUMMARY":"ملخص الطلب",
      "TOTAL":'الإجمالي',
      "ADD NEW ADDRESS":"أضف عنوان جديد",
      "FULL NAME":"الاسم الكامل",
      "PHONE NUMBER":"رقم الهاتف",
      "ALTERNATIVE PHONE NUMBER":"رقم هاتف بديل",
      "PINCODE":" رمز البريد",
      "STATE":"الدولة ",
      "CITY":"مدينة",
      "AREA NAME":"اسم المنطقة",
      "BUILDING NAME":"اسم المبنى",
      "LANDMARK":"معلم",
      "SAVE ADDRESS":"حفظ العنوان",
      "CANCEL":"إلغاء ",
      "LOGIN":"تسجيل الدخول",
      "WELCOME TO BHAKSHANANGAL!":"مرحبا بكم في باكشانجال!",
      "REGISTER":"يسجل",
      "Our Mission":"مهمتنا",
      "&copy; 2024 Bhakshanangal Foods Private Ltd. All Rights Reserved.":"&ينسخ؛ 2024 شركة بهاكشانانجال للأغذية الخاصة المحدودة. جميع الحقوق محفوظة.",
      "YOUR CART":"طلبك",
      "QUANTITY":"كمية",
      "PRICE":"سعر",
      "REMOVE":"يزيل",
      "TOTAL PRICE":"السعر الإجمالي",
      "CHECKOUT":"الدفع",
      "LOGOUT":"تسجيل الخروج",
      "ALL":"الجميع",
      "DISTRICT":"محافظة",
      'Email Address':'عنوان البريد الإلكتروني',
      "Password":"كلمة المرور",
      "Forget your password?":"هل نسيت كلمة المرور الخاصة بك؟",
      English: "English",
      Malayalam: "മലയാളം",
      Hindi: "हिंदी",
      // Tamil: "தமிழ்",
      Arabic: "عربي",
    },
  },

  hi: {
    translation: {
      "Login here":"यहाँ लॉगिन करें",
      "Already have an account?":"क्या आपके पास पहले से एक खाता है?",
      "Enter Confirm Password":" पासवर्ड की पुष्टि करें",
      "Confirm Password":"पासवर्ड की पुष्टि करें",
      "Name":"नाम",
      "Enter Your Name":"अपना नाम दर्ज करें",
      "Remember me": "मुझे याद रखें",
      "Enter Password":"पासवर्ड दर्ज करें",
      "Enter Email Address": "ईमेल पता दर्ज करें",
      "Create account":"खाता बनाएं",
      "Don't have an account?":"क्या आपका खाता नहीं है?",
      "Forget your password":"क्या आप अपना पासवर्ड भूल गए हैं?",
      "BEST SELLING PRODUCTS": "सर्वश्रेष्ठ विक्रेता उत्पाद",
      "NEW ARRIVALS": "नए आगमन",
      "OUR PRODUCTS": "हमारे उत्पाद",
      "BUY NOW": "अभी खरीदें",
      "QUICK LINKS": "त्वरित लिंक",
      "PRIVACY AND POLICY": "गोपनीयता और नीति",
      "Select Language": "भाषा चुनें",
      "TERMS AND USE": "शर्तें और उपयोग",
      "FAQ": "एफ ए क्यू",
      "CONTACT US": "हमसे संपर्क करें",
      "ABOUT US": "हमारे बारे में",
      "KEEP IN TOUCH": "संपर्क में रहें",
      "BUILDING A2 IFZA, BUSINESS PARK,": "बिल्डिंग A2 IFZA, बिजनेस पार्क,",
      "DUBAI SILICON OASIS, DUBAI": "दुबई सिलिकॉन ओएसिस, दुबई",
      "MG CORPORATE CENTRE": "एमजी कॉर्पोरेट सेंटर",
      "NEAR IOC PUMP, ULLOOR JUNCTION, MCPO, TVM, KERALA 695011":
        "आईओसी पंप के पास, उल्लोर जंक्शन, एमसीपीओ, टीवीएम, केरल",
      "ADD TO CART": "कार्ट में जोड़ें",
      "MOVE TO BAG":"बैग में ले जाएँ",
      "DELIVERY TO":"को सुपुर्दगी",
      "SHIPPPING ADDRESS":"शिपिंग पता",
      "SELECT A SAVED ADDRESS":"एक सहेजा गया पता चुनें",
      "CHOOSE AN ADDRESS":"एक पता चुनें",
      "SELECT PAYMENT OPTION":"भुगतान विकल्प चुनें",
      "ONLINE PAYMENT":"ऑनलाइन भुगतान",
      "CASH ON DELIVERY":"नकद पर डिलीवरी",
      "PAYMENT MODE":"भुगतान मोड",
      "ORDER SUMMARY":"ऑर्डर सारांश",
      "TOTAL":'कुल',
      "ADD NEW ADDRESS":"नया पता जोड़ें",
      "FULL NAME":"पूरा नाम",
      "PHONE NUMBER":" फोन नंबर",
      "ALTERNATIVE PHONE NUMBER":"	वैकल्पिक फोन नंबर",
      "PINCODE":"पिन कोड",
      "STATE":" राज्य ",
      "CITY":"नगर",
      "BUILDING NAME":"बिल्डिंग नाम",
      "AREA NAME":"क्षेत्र का नाम",
      "LANDMARK":"मील का पत्थर ",
      "SAVE ADDRESS":"पता सहेजें",
      "CANCEL":" रद्द करें",
      "LOGIN":"लॉग इन करें",
      "WELCOME TO BHAKSHANANGAL!":"बख्शंगल में आपका स्वागत है!",
      "REGISTER":"पंजीकरण करवाना",
      "&copy; 2024 Bhakshanangal Foods Private Ltd. All Rights Reserved.":"&प्रतिलिपि; 2024 भक्षणंगल फूड्स प्राइवेट लिमिटेड सर्वाधिकार सुरक्षित।",
      "Our Mission":"हमारा विशेष कार्य",
      "YOUR CART":"आपका आदेश",
      "QUANTITY":"मात्रा",
      "PRICE":"कीमत",
      "REMOVE":"निकालना",
      "TOTAL PRICE":"कुल कीमत",
      "CHECKOUT":"चेक आउट",
      "LOGOUT":"लॉग आउट",
      "ALL":"सभी",
      "DISTRICT":"ज़िला ",
      'Email Address':"ईमेल पता",
      "Password":"पासवर्ड",
      English: "English",
      Malayalam: "മലയാളം",
      Hindi: "हिंदी",
      // Tamil: "தமிழ்",
      Arabic: "عربي",
    },
  },

  // ta: {
  //   translation: {
  //     "BEST SELLING PRODUCTS": "சிறந்த விற்பனைப் பொருட்கள்",
  //     "NEW ARRIVALS": "புதிய வரவுகள்",
  //     "OUR PRODUCTS": "எங்கள் தயாரிப்புகள்",
  //     "BUY NOW":"இப்போது வாங்கவும்",
  //     "Select Language": "மொழி தேர்ந்தெடுக்கவும்",
  //     "QUICK LINKS":"வெகுஜன இணைப்புகள் ",
  //     "PRIVACY AND POLICY":"தனியுரிமை மற்றும் கொள்கை",
  //     "TERMS AND USE":"விதிமுறைகள் மற்றும் பயன்பாடு",
  //     English: "English",
  //     Malayalam: "മലയാളം",
  //     Hindi: "हिंदी",
  //     Tamil: "தமிழ்",
  //     Arabic: "عربي",
  //   },
  // },
};

i18n
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Bind i18next to React
  .init({
    resources, // Use the inlined resources
    supportedLngs: ["en", "ml", "hi", "ta", "ar"],
    fallbackLng: "en", // Default language
    detection: {
      order: ["localStorage", "cookie", "navigator"],
      caches: ["localStorage"],
    },
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    debug: true,
  });

export default i18n;
