import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./verification.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const VerificationCode = () => {
  const [code, setCode] = useState(["", "", "", ""]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const formData = location.state; // Assuming formData includes the email
  const navigate = useNavigate();

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Move to the next input field if a digit was entered
      if (value && index < code.length - 1) {
        document.getElementById(`digit-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && code[index] === "" && index > 0) {
      document.getElementById(`digit-${index - 1}`).focus();
    }
  };

  const handleVerify = async () => {
    const verificationCode = code.join(""); // Combine the code array into a single string

    if (verificationCode.length !== 4) {
      alert("Please enter the 4-digit code.");
      return;
    }

    setIsLoading(true);

    try {
      const data = {
        email: formData.email, // Assuming formData has the user's email
        code: verificationCode,
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/verification",
        data,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      //   const data = await response.json();

      if (response.data.result == true) {
        alert(response.data.message);
        navigate("/login");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="verification-container">
      <div className="verify-container">
        <p>ENTER YOUR VERIFICATION CODE</p>
        <div className="verify-code">
          {code.map((digit, index) => (
            <input
              key={index}
              id={`digit-${index}`}
              type="text"
              value={digit}
              maxLength="1"
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="verification-input"
            />
          ))}
        </div>
        <div className="confirm-button mt-3">
          <Button
            className="verifying-button w-100"
            onClick={handleVerify}
            disabled={isLoading}
          >
            {isLoading ? "VERIFYING..." : "VERIFY CODE"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VerificationCode;
