import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import "./login.css";
import logoin from "../../../Assests/logoin.png";
import login from "../../../Assests/login.jpg";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';



const SignInForm = () => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const lan =localStorage.getItem('i18nextLng');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (event,language = localStorage.getItem('i18nextLng')) => {
    event.preventDefault();
    const form = event.currentTarget;
    let isValid = true;

    // Reset error messages
    setEmailError("");
    setPasswordError("");

    // Validate email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email.");
      isValid = false;
    }

    // Validate password strength
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include an uppercase letter, a number, and a special character."
      );
      isValid = false;
    }

    // If the form is invalid, stop submission
    if (!isValid) {
      setValidated(false);
      return;
    }

    // Proceed with form submission if valid
    if (form.checkValidity() === true) {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/login",
          {
            device_id: "AR01QW4561255",
            device_os: "android",
            device_token: "5446",
            password,
            email,
            language,
            app_version: "2",
          }
        );

        console.log("Login successful:", response.data);

        // Ensure that response.data contains user_id
        if (response.data && response.data.user_id) {
          localStorage.setItem("user_id", response.data.user_id);
          localStorage.setItem("user_api_key", response.data.user_api_key);
          navigate("/", {
            state: {
              user_id: response.data.user_id,
              user_api_key: response.data.user_api_key,
            },
          });
        } else {
          setErrorMessage("Login failed. Invalid response data.");
        }
      } catch (error) {
        setErrorMessage("Login failed. Please check your email and password.");
        console.error("Error logging in:", error);
        if (error.response) {
          // This will capture HTTP errors (e.g., 400, 500)
          console.error("Response error:", error.response);
        }
      }
    } else {
      setErrorMessage("Please fill out all required fields.");
    }

    setValidated(true);
  };
  

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-4 mt-2">
        <Form.Label  style={{direction}}>{t('Email Address')}</Form.Label>
        <Form.Control
          type="email"
          placeholder={t("Enter Email Address")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isInvalid={!!emailError}
          required
        />
        <Form.Control.Feedback type="invalid">
          {emailError || "Please enter a valid email."}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 mt-2">
        <Form.Label style={{direction}}>{t('Password')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t("Enter Password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isInvalid={!!passwordError}
          required
        />
        <Form.Control.Feedback type="invalid">
          {passwordError || "Please enter your password."}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          id="signin2-remember-me"
          label={t("Remember me")}
        />
      </Form.Group>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      <Button
        variant=""
        type="submit"
        className="ezy__signin2-btn-submit w-100"
        style={{direction}}>
        {t("LOGIN")}
       
      </Button>
      <Button href="/otp" variant="" className="forget-password-btn w-100" style={{direction}}>
      {t("Forget your password?")} 
      </Button>
    </Form>
  );
};

const SignInFormCard = () => (
  <Card className="ezy__signin2-form-card">
    <Card.Body className="p-0">
      <div className="logodiv">
        <img className="login-page-logo" src={logoin} alt="" />
      </div>
      <SignInForm />
      <div className="text-center mt-4 mt-md-5">
        <p className="mb-0 opacity-50 lh-1" style={{direction}}> {t("Don't have an account?")}</p>
        <Button
          href="/register"
          variant="link"
          className="py-0 text-dark text-decoration-none"
          style={{direction}}>
          {t("Create account")} 
         
        </Button>
      </div>
    </Card.Body>
  </Card>
);

const SignIn2 = () => {
  // Clear localStorage on page refresh
  useEffect(() => {
    localStorage.clear();
  }, []);

  // Handle logout functionality

  return (
    <section className="ezy__signin2 light d-flex">
      <Container>
        <Row className="justify-content-between h-100">
          <Col lg={5} md={12}>
            {" "}
            {/* Adjust columns for small screens */}
            <div
              className="ezy__signin2-bg-holder d-none d-lg-block h-100"
              style={{
                backgroundImage: `url(${login})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Col>
          <Col lg={5} md={12} className="py-5">
            <Row className="align-items-center h-100">
              <Col xs={12}>
                <SignInFormCard />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignIn2;
