import React, { useEffect, useState } from "react";
import "./home.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCaretRight,faHeart,faCartShopping} from "@fortawesome/free-solid-svg-icons";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Carousel from "react-bootstrap/Carousel";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

function Home() {
  const { t } = useTranslation();
  const location = useLocation();
  const [carouselItems, setCarouselItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [ourproducts, setOurproducts] = useState([]);
  const [latest, setLatest] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userApiKey, setUserApiKey] = useState(null);
  const navigate = useNavigate();
  const lan =localStorage.getItem('i18nextLng');
  // const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    const storedUserApiKey = localStorage.getItem("user_api_key");

    if (storedUserId) {
      setUserId(storedUserId);
    }

    if (storedUserApiKey) {
      setUserApiKey(storedUserApiKey);
    }
  }, []);

  useEffect(() => {
    const fetchCarouselData = async (language = localStorage.getItem('i18nextLng')) => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/banner/list",
          {
            language,
          }
        );
        console.log(response.data.carousel);
        setCarouselItems(response.data.carousel);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching carousel data:", error);
        setLoading(false);
      }
    };

    fetchCarouselData(); // Call the function to fetch data
  }, []);

  useEffect(() => {
    const bestSellingData = async (language = localStorage.getItem('i18nextLng')) => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/product/best-selling",
          {
            params: {
              view: "month",
            },language
            
             
        
          }
        );
        console.log(response.data);
        setProducts(response.data.data);

        console.log("API Response:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    bestSellingData();
  }, []);
  console.log(products);

  useEffect(() => {
    const ourProducts = async (language = localStorage.getItem('i18nextLng')) => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/productlist",  {
            language
          }
        );
        console.log(response.data);
        setOurproducts(response.data.data);
        console.log("API Response:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    ourProducts();
  }, []);
  useEffect(() => {
    const fetchLatestProducts = async (language = localStorage.getItem('i18nextLng')) => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/latest-product",
          {
            language,
          }
        );
        if (response.data.result) {
          setLatest(response.data.data); // Set latest products data from API response
        }
        console.log("API Response:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchLatestProducts();
  }, []);

  const handleClick = (productId) => {
    const userApiKey = localStorage.getItem("user_api_key");
    const userId = localStorage.getItem("user_id");

    if (!userApiKey || !userId) {
      // Navigate to the login page if user_api_key or user_id is null
      navigate("/login");
    } else {
      // Navigate to the product view page with the required data
      navigate("/viewproduct", { state: { productId} });
    }
  };

  const handleCart = (productId) => {
    const userApiKey = localStorage.getItem("user_api_key");
    const userId = localStorage.getItem("user_id");

    if (!userApiKey || !userId) {
      // Navigate to the login page if user_api_key or user_id is null
      navigate("/login");
    } else {
      // Navigate to the product view page with the required data
      navigate("/viewproduct", { state: { productId} });
    }
  };

  const handleAddToCart = async (product_id, currentCartStatus,language = localStorage.getItem('i18nextLng')) => {
    try {
      // Ensure userId and userApiKey are available
      if (!userId || !userApiKey) {
        console.error("User is not authenticated");
        alert("Please log in to manage your cart.");
        return;
      }

      // Determine the new cart status
      const newCartStatus = currentCartStatus ? 0 : 1;

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/addcart",
        {
          cart: newCartStatus, // Toggle cart status
          product_id,
          language
        },
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
          },
        }
      );console.log(response.data,"hiiiyyyyy");
      

      if (response.data.result) {
        // Update the product state locally
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.product_id === product_id
              ? { ...product, isInCart: newCartStatus }
              : product
          )
        );

        if (newCartStatus) {
          alert("Product added to cart.");
        } else {
          alert("Product removed from cart.");
        }
      } else {
        console.error("Failed to update cart:", response.data.message);
        alert(response.data.message || "Failed to update cart.");
      }
    } catch (error) {
      console.error("Error updating cart:", error);
      alert("An error occurred while updating the cart. Please try again.");
    }
  };

  const handleWishlistClick = async (product_id, currentWishlistStatus,language = localStorage.getItem('i18nextLng')) => {
    try {

      if (!userId || !userApiKey) {
        console.error("User is not authenticated");
        alert("Please log in to manage your cart.");
        return;
      }
      // Determine the new wishlist status
      const newWishlistStatus = currentWishlistStatus ? 0 : 1;

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/wishlist",
        {language,
          wish: newWishlistStatus, // Toggle wishlist status
          product_id
        },
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
          },
        }
      );

      if (response.data.result) {
        // Update the product state locally
        setProducts((prevProducts) =>
          prevProducts?.map((product) =>
            product.product_id === product_id
              ? { ...product, isInWishlist: newWishlistStatus }
              : product
          )
        );

        if (newWishlistStatus) {
          alert("Product added to wishlist.");
        } else {
          alert("Product removed from wishlist.");
        }
      } else {
        console.error("Failed to update wishlist:", response.data.message);
        alert("Please log in to manage your wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      alert("An error occurred while updating the wishlist. Please try again.");
    }
  };
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  return (
    <>
    
      <div className="full-screen-carousel">
        <Carousel
          nextIcon={
            <FaChevronRight style={{ fontSize: "24px", color: "#000" }} />
          }
          prevIcon={
            <FaChevronLeft style={{ fontSize: "24px", color: "#000" }} />
          }
        >
          {carouselItems && carouselItems.length > 0 ? (
            carouselItems.map((item) => (
              <Carousel.Item key={item.banner_id}>
                <img
                  className="home-image d-block w-100"
                  src={`https://lunarsenterprises.com:3000/${item.banner_image}`}
                  alt={item.banner_name || "Default banner"}
                />
              </Carousel.Item>
            ))
          ) : (
            <div>
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/033/746/375/small_2x/a-cup-of-tea-and-a-cup-of-coffee-on-a-table-ai-generated-photo.jpg"
                alt="Default banner"
                className="home-image d-block w-100"
              />
            </div>
          )}
        </Carousel>
      </div>
      <div className="container">
        <div>
          {/* 1st carousal */}
          <h2 className="homepage-heading" style={{direction}}>{t('BEST SELLING PRODUCTS')}</h2>
          <div className="row">
            <div className="carousel-container" style={{ width: "100%" }}>
              <Splide
                className="heghttest"
                options={{
                  type: "loop",
                  perPage: 4,
                  perMove: 1,
                  autoplay: true,
                  interval: 3000,
                  pauseOnHover: true,
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    1200: { perPage: 4 },
                    768: { perPage: 2 },
                    576: { perPage: 1 },
                  },
                }}
              >
                {products.map((product) => (
                  <SplideSlide
                    key={product.product_id}
                    className="carousel-image"
                  >
                    <div className="image-container">
                     
                        <button className="add-to-cart-btn"onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior
                        handleAddToCart(product.product_id, product.isInCart);
                      }}>
                          <FontAwesomeIcon
                            className="home-cart-icon"
                            icon={faCartShopping}
                          />
                        </button>
                     
                      
                        <button className="wishlist-btn"  onClick={(e) => {
                        e.preventDefault();
                        handleWishlistClick(
                          product.product_id,
                          product.isInWishlist
                        );
                      }}>
                          <FontAwesomeIcon
                            className="home-heart-icon"
                            icon={faHeart}
                          />
                        </button>
                   

                      <img
                        src={`https://lunarsenterprises.com:3000/${product.image_file}`}
                        alt={product.product_name}
                        className="product-image"
                      />

                      <div className="overlay">
                        <p className="name">{product.product_name}</p>
                        <p className="price">Rs {product.price}.OO</p>

                        <button
                          onClick={() => handleClick(product.product_id)} // Pass product_id here
                          className="buy-now-btn"
                          style={{direction}}
                        >
                         {t('BUY NOW')}
                          
                        </button>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </div>

        <div>
          {/* 2nd carousal */}
          <h2 className="homepage-heading"> {t('NEW ARRIVALS')}</h2>
          <div className="row">
            <div className="carousel-container" style={{ width: "100%" }}>
              <Splide
                options={{
                  type: "loop",
                  perPage: 4,
                  perMove: 1,
                  autoplay: true,
                  interval: 3000,
                  pauseOnHover: true,
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    1200: { perPage: 4 },
                    768: { perPage: 2 },
                    576: { perPage: 1 },
                  },
                }}
              >
                {latest.map((product) => (
                  <SplideSlide
                    key={product.product_id}
                    className="carousel-image"
                  >
                    <div className="image-container">
                    <button className="add-to-cart-btn"onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior
                        handleAddToCart(product.product_id, product.isInCart);
                      }}>
                          <FontAwesomeIcon
                            className="home-cart-icon"
                            icon={faCartShopping}
                          />
                        </button>
                        <button className="wishlist-btn"  onClick={(e) => {
                        e.preventDefault();
                        handleWishlistClick(
                          product.product_id,
                          product.isInWishlist
                        );
                      }}>
                          <FontAwesomeIcon
                            className="home-heart-icon"
                            icon={faHeart}
                          />
                        </button>
                      <img
                        src={`https://lunarsenterprises.com:3000/${product.image_file}`}
                        alt={product.product_name}
                        className="product-image"
                      />
                      <div className="overlay">
                        <p className="name">{product.product_name}</p>
                        <p className="price">Rs {product.price}.00</p>
                        <button
                          onClick={() => handleClick(product.product_id)}
                          className="buy-now-btn"
                          style={{direction}}
                          >
                           {t('BUY NOW')}
                        </button>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </div>
        {/* 3rd carousal */}
        <div>
          <h2 className="homepage-heading">{t('OUR PRODUCTS')}</h2>
          <div className="row">
            <div className="carousel-container" style={{ width: "100%" }}>
              <Splide
                className="heghttest"
                options={{
                  type: "loop",
                  perPage: 4,
                  perMove: 1,
                  autoplay: true,
                  interval: 3000,
                  pauseOnHover: true,
                  pagination: false,
                  arrows: true,
                  breakpoints: {
                    1200: { perPage: 4 },
                    768: { perPage: 2 },
                    576: { perPage: 1 },
                  },
                }}
              >
                {ourproducts.map((ourProduct) => (
                  <SplideSlide
                    key={ourProduct.product_id}
                    className="carousel-image"
                  >
                    <div className="image-container">
                      {/* Add to Cart button */}
                      <button className="add-to-cart-btn"onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior
                        handleAddToCart(ourProduct.product_id, ourProduct.isInCart);
                      }}>
                          <FontAwesomeIcon
                            className="home-cart-icon"
                            icon={faCartShopping}
                          />
                        </button>
                      
                      {/* Wishlist button */}
                      <button className="wishlist-btn"  onClick={(e) => {
                        e.preventDefault();
                        handleWishlistClick(
                          ourProduct.product_id,
                          ourProduct.isInWishlist
                        );
                      }}>
                          <FontAwesomeIcon
                            className="home-heart-icon"
                            icon={faHeart}
                          />
                        </button>

                      <img
                        src={`https://lunarsenterprises.com:3000/${ourProduct.image_file}`}
                        alt={ourProduct.product_name}
                        className="product-image"
                      />

                      <div className="overlay">
                        <p className="name">{ourProduct.product_name}</p>
                        <p className="price">Rs {ourProduct.price}.00</p>
                        <button
                          onClick={() => handleClick(ourProduct.product_id)} // Pass product_id here
                          className="buy-now-btn"
                          style={{direction}}
                        >
                         {t('BUY NOW')}
                        </button>
                      </div>
                    </div>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          </div>
        </div>
        <div>
          {/* <div className="view-buttondiv">
            <Button href="/products"className="viewAllproducts">View All Products <FontAwesomeIcon className="ms-2" icon={faCaretRight} /></Button></div> */}
        </div>
      </div>
      {/* <div className="bhakshanangal-image row mt-5">
      <div className="image-bhakshanangal col-4">
        <img className="bhakshanagal-quote-image" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-7hD-WrxluIZeLtJKPYc_8yw4E3-4b-QXgg&s" alt="" />
      </div>
      <div className="bhakshanagal-image-quote col-8">
  <h4 className="bhakshanangal-qoute">“𝔗𝔥𝔢𝔯𝔢 𝔦𝔰 𝔫𝔬 𝔰𝔦𝔫𝔠𝔢𝔯𝔢𝔯 𝔩𝔬𝔳𝔢 <br /> 𝔱𝔥𝔞𝔫 𝔱𝔥𝔢 𝔩𝔬𝔳𝔢 𝔬𝔣 𝔣𝔬𝔬𝔡,”</h4>
</div> */}

      {/* </div> */}
    </>
  );
}

export default Home;
