import React, { useState } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./register.css";
import logoin from "../../../Assests/logoin.png";
import login from "../../../Assests/login.jpg";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';

const SignInForm = () => {
  const { t } = useTranslation();
  const lan =localStorage.getItem('i18nextLng');
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

  const handleSubmit = async (event,language = localStorage.getItem('i18nextLng')) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match.");
      return;
    } else {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/signup",
          {
            language,
          },
          {
            name: formData.name,
            email: formData.email,
            password: formData.password,
          }
        );
        if (response.data.status === true) {
          setError("");
          alert(response.data.message);
          navigate("/verify", { state: formData });
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setError("Registration failed. Please try again.");
      }
    }

    setValidated(true);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    // Only update name if it's valid or empty
    if (name === "name" && !isNameValid(value)) {
      setError("Name should contain letters and spaces only.");
      return;
    } else {
      setError(""); // Clear error if name is valid
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  

  // Validation functions
// Updated isNameValid function
const isNameValid = (name) => /^[A-Za-z\s]*$/.test(name);
  const isEmailValid = (email) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPasswordStrong = (password) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {error && <p className="text-danger">{error}</p>}
      {success && <p className="text-success">{success}</p>}

      {/* Name Field */}
	  <Form.Group className="mb-4 mt-2">
        <Form.Label style={{direction}}>{t('Name')}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t("Enter Your Name")}
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          isInvalid={!isNameValid(formData.name) && formData.name !== ""}
        />
        <Form.Control.Feedback type="invalid">
          Name should contain letters only.
        </Form.Control.Feedback>
        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
      </Form.Group>


      {/* Email Field */}
      <Form.Group className="mb-4 mt-2">
        <Form.Label style={{direction}}>{t('Email Address')}</Form.Label>
        <Form.Control
          type="email"
          placeholder={t("Enter Email Address")}
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          isInvalid={!isEmailValid(formData.email) && formData.email !== ""}
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid email address.
        </Form.Control.Feedback>
        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
      </Form.Group>

      {/* Password Field */}
      <Form.Group className="mb-2 mt-2">
        <Form.Label style={{direction}}>{t('Password')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t("Enter Password")}
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          minLength={6} 
          isInvalid={!isPasswordStrong(formData.password) && formData.password !== ""}
        />
        <Form.Control.Feedback type="invalid">
          Password must be at least 8 characters, include uppercase, lowercase, digit, and special character.
        </Form.Control.Feedback>
        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
      </Form.Group>

      {/* Confirm Password Field */}
      <Form.Group className="mb-2 mt-2">
        <Form.Label style={{direction}}>{t('Confirm Password')}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t("Enter Confirm Password")}
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
          minLength={6} 
        />
        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          id="signin2-remember-me"
          label={t("Remember me")}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="ezy__signin2-btn-submit w-100"
        style={{direction}}>
        {t("REGISTER")}
        
      </Button>
    </Form>
  );
};

const SignInFormCard = () => (
  <Card className="ezy__signin2-form-card">
    <Card.Body className="p-0">
      <div className="logodiv">
        <img className="login-page-logo" src={logoin} alt="" />
      </div>
      <SignInForm />
      <div className="text-center mt-3 md-5">
        <p className="mb-0 opacity-50 lh-1" style={{direction}}>
        {t("Already have an account?")}</p>
        <Button href="/login" variant="link" className="py-0 text-dark text-decoration-none"style={{direction}}>
        {t("Login here")}
          
        </Button>
      </div>
    </Card.Body>
  </Card>
);

const SignIn2 = () => {
  return (
    <section className="ezy__signin2 light d-flex">
      <Container>
        <Row className="justify-content-between h-100">
          <Col lg={5}>
            <div
              className="ezy__signin2-bg-holder d-none d-lg-block h-100"
              style={{
                backgroundImage: `url(${login})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          </Col>
          <Col lg={5} className="py-5">
            <Row className="align-items-center h-100">
              <Col xs={12}>
                <SignInFormCard />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignIn2;
