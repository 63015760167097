import React, { startTransition, useEffect, useState } from "react";
import "./header.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faBagShopping, faHeart, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assests/logo.png";
import axios from "axios";
import { useTranslation } from "react-i18next";

function Header() {


  const lan =localStorage.getItem('i18nextLng'); // Store selected language


  
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [showDropdown, setShowDropdown] = useState(true);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if user_id and user_api_key exist in local storage
    const userId = localStorage.getItem("user_id");
    const apiKey = localStorage.getItem("user_api_key");

    if (userId && apiKey) {
      setIsLoggedIn(true); // Set logged-in state
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      setShowDropdown(true);
    }
  }, [location.pathname]);

  useEffect(() => {

    fetchCategories();
  }, []);


  const fetchCategories = async (language = localStorage.getItem('i18nextLng')) => {
    try {
      const response = await axios.post("https://lunarsenterprises.com:3000/bhakshanangal/categorylist", {
        language,
      });
  
      const data = response.data;
  
      if (data.result) {
        setCategories(data.list);
        setFilteredCategories(data.list); // Set filtered categories to all initially
      } else {
        console.error("Failed to retrieve data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const handleSelect = (categoryId) => {
    navigate("/products", { state: { categoryId } });
  };

  const handleLogout = () => {
    // Remove user data from localStorage
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_api_key");
    setIsLoggedIn(false); // Update the login state
    navigate("/");
  };

  // Filter categories based on search term
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredCategories(categories);
    } else {
      const filtered = categories.filter(category =>
        category.category_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filtered);
    }
  }, [searchTerm, categories]);




  const { t, i18n } = useTranslation();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';



  const [first, setfirst] = useState()


  // console.log(first,'firstfirstfirst');
  

  const handleChange = (e) => {
    const selectedLanguage = e.target.value;
  
    // Update the language globally
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('i18nextLng', selectedLanguage); // Store selected language
    
    // Update `lan` state and fetch categories with new language
    fetchCategories(selectedLanguage); // Pass the selected language to the function
  };
  useEffect(() => {
    const language = localStorage.getItem('i18nextLng') || 'en'; // Default to 'en' if no language is set
    fetchCategories(language);
  }, []);
    
  return (
    <>
      {/* Language Selector */}
      <div className="language-selector text-end bg-dark">
      <Form.Select className="language-button" onChange={handleChange} value={i18n.language}>
        <option value="en">{t('Select Language')}</option> {/* Translated text */}
        <option value="en">{t('English')}</option>
        <option value="ml">{t('Malayalam')}</option>
        <option value="hi">{t('Hindi')}</option>
        {/* <option value="ta">{t('Tamil')}</option> */}
        <option value="ar">{t('Arabic')}</option>
      </Form.Select>
    </div>

      {/* Navbar */}
      <Navbar expand="lg" className="header">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} width="100" height="120" className="d-inline-block align-top" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll></Nav>

            {/* Search Bar */}
            <div className="search-container">
              <div className="search">
                <div className="left-side">
                  <div className="select-option-button">
                    <Form.Select
                      aria-label="Category select"
                      className="form-design"
                      onChange={(e) => handleSelect(e.target.value)}
                    >
                      <option className="option-design" value=""style={{direction}}>{t('ALL')}
                        
                      </option>
                      {filteredCategories?.map((category) => (
                        <option key={category.category_id} value={category.category_id}>
                          {category.category_name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="search-center">
                  <input
                    type="text"
                    placeholder="Search for Our Products"
                    className="search-input"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="right-side">
                  <Button className="btn-search-K">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </Button>
                </div>
              </div>
            </div>

            {/* Login/Register or Wishlist/Cart */}
            <div className="login-register d-flex align-items-center">
              {isLoggedIn ? (
                <div className="icon-container">
                  <a href="/wishlist" className="login-button">
                    <FontAwesomeIcon icon={faHeart} />
                  </a>
                  <a href="/addtocart" className="login-button">
                    <FontAwesomeIcon icon={faBagShopping} />
                  </a>
                  <Button
                    variant="link"
                    onClick={handleLogout}
                    className="login-button"
                    style={{direction}}>{t('LOGOUT')}
                    
                  </Button>
                </div>
              ) : (
                <>
                  <Button
                    variant="link"
                    onClick={() => navigate("/login")}
                    className="login-button"style={{direction}}>{t('LOGIN')}  
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => navigate("/register")}
                    className="login-button"
                    style={{direction}}>{t('REGISTER')}
                  </Button>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Container> 
      </Navbar>
      

      {/* <div className="headline-row">
      <Link to="/" className="headline"><div >HOME</div></Link>
      <Link to="/products" className="headline"><div >PRODUCTS</div></Link>
      <Link to="/about-us" className="headline"><div>ABOUT</div></Link>
      <Link to="/contact" className="headline"><div >CONTACT</div></Link>
    </div>
       */}

      {/* Highlights Section */}
      {location.pathname === "/" && (
        <div className="highlights-container">
          {filteredCategories?.map((category) => (
            <div
              className="highlight"
              key={category.category_id}
              onClick={() => handleSelect(category.category_id)}
              style={{ cursor: "pointer" }}
            >
              <div className="highlight-icon">
                <img
                  src={`https://lunarsenterprises.com:3000/${category.category_image}`}
                  alt={category.category_name}
                  className="dropdown-image"
                />
              </div>
              <span className="highlight-title">{category.category_name}</span>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Header;
