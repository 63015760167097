import React from "react";
import "./faq.css";

function Faq() {
  return (
    <div className="faq">
      <h6 className="section-heading">Frequently Asked Questions</h6>
      <hr />
      
      <div>
        <p><b>Question 1 ?</b> What ingredients are used in your food?</p>
        <p>Our dishes are made with fresh, locally sourced ingredients, including vegetables, meats, spices, and grains. We also offer vegan and gluten-free options.</p>
      </div>
      
      <div>
        <p><b>Question 2 ?</b> Do you offer vegetarian or vegan options?</p>
        <p>Yes, we have a variety of vegetarian and vegan dishes available. You can find them in the 'Vegan & Vegetarian' section of our menu.</p>
      </div>

      <div>
        <p><b>Question 3 ?</b> Can I customize my order?</p>
        <p>Absolutely! You can customize most of our dishes to suit your taste preferences. Just let us know your requirements when placing an order.</p>
      </div>

      <div>
        <p><b>Question 4 ?</b> Do you provide nutritional information?</p>
        <p>Yes, we provide nutritional information for all our menu items. You can view them on our website or ask our staff for details.</p>
      </div>

      <div>
        <p><b>Question 5 ?</b> Are your foods made without preservatives?</p>
        <p>We strive to serve fresh, preservative-free food whenever possible. Some of our packaged items may contain natural preservatives for longer shelf life.</p>
      </div>

      <div>
        <p><b>Question 6 ?</b> Do you offer gluten-free options?</p>
        <p>Yes, we have gluten-free options available. Please check the menu or ask our staff for assistance in choosing gluten-free meals.</p>
      </div>

      <div>
        <p><b>Question 7 ?</b> What is your policy on food allergies?</p>
        <p>We take food allergies seriously and can accommodate most dietary restrictions. Please inform us of any allergies when placing your order.</p>
      </div>

      <div>
        <p><b>Question 8 ?</b> How do I place an order for delivery?</p>
        <p>You can place an order through our website or via our mobile app. We also accept orders by phone for delivery.</p>
      </div>

      <div>
        <p><b>Question 9 ?</b> Do you offer catering services?</p>
        <p>Yes, we offer catering services for events of all sizes. Contact us for more information and to discuss your event's menu.</p>
      </div>

      <div>
        <p><b>Question 10 ?</b> Can I order food online?</p>
        <p>Yes, you can order food online through our website or mobile app. We offer both pickup and delivery options.</p>
      </div>
    </div>
  );
}

export default Faq;
