import React from "react";
import "./terms.css";

function Terms() {
  return (
    <>
      <div className="terms-conditions container">
        <h5 className="headline-terms">TERMS AND CONDITIONS</h5>
        <div>
          <p className="sub-writings-terms mt-5">

          <b>Effective date: May 6, 2024</b>
          <br />
            Welcome to Bhakshanangal, an online marketplace for Kerala Taste
            products. By accessing or using our website and services, you agree
            to comply with and be bound by the following Terms and Conditions.
            Please read them carefully.
          </p>
        </div>

        <h6 className="short-headings">1. Acceptance of Terms</h6>
        <p className="sub-writings-terms">
          By accessing or using our website, including purchasing products, you
          agree to be bound by these Terms and Conditions. If you do not agree
          with these terms, please do not use our services.
        </p>

        <h6 className="short-headings">2. Eligibility</h6>
        <p className="sub-writings-terms">
          You must be atleast [15] years old to place an order on this website.
          By making a purchase, you represent and warrant that you are at least
          the required age and have the legal capacity to enter into this
          agreement.
        </p>

        <h6 className="short-headings">3. Products and Availability</h6>
        <p className="sub-writings-terms">
          <ul>
            <li>
              Product Descriptions: We strive to provide accurate descriptions
              and images of our products. However, we do not guarantee that the
              descriptions are completely accurate, error-free, or up-to-date.
            </li>
            <li>
              Availability: All products are subject to availability. If a
              product is out of stock, we will notify you as soon as possible
              and offer an alternative or a refund.
            </li>
          </ul>
        </p>

        <h6 className="short-headings">4. Pricing and Payment</h6>
        <p className="sub-writings-terms">
          <ul>
            <li>
              Pricing: All prices listed on the Site are in [Currency] and
              include applicable taxes, unless otherwise stated. We reserve the
              right to change prices at any time.
            </li>
            <li>
              Payment: Payment is due at the time of purchase. We accept
              payments through [list of accepted payment methods, e.g.,
              credit/debit cards, PayPal, etc.].
            </li>
            <li>
              Order Confirmation: Once your order is placed, you will receive an
              email confirmation. This does not signify the acceptance of your
              order. We reserve the right to cancel or refuse any order at our
              discretion.
            </li>
          </ul>
        </p>

        <h6 className="short-headings">5. Shipping and Delivery</h6>
        <p className="sub-writings-terms">
          <li>
            {" "}
            Shipping Fees: Shipping fees are calculated based on your order and
            delivery address. You will be notified of the shipping costs at
            checkout.
          </li>
          <li>
            Delivery Time: Estimated delivery times are provided at checkout.
            While we strive to meet these estimates, delays may occur due to
            unforeseen circumstances.
          </li>
          <li>
            International Orders: For international orders, customs duties and
            taxes may apply, which are the responsibility of the customer.
          </li>
        </p>

        <h6 className="short-headings">6. Returns and Refunds</h6>
        <p className="sub-writings-terms">
          <ul>
            <li>
              Return Policy: We accept returns within [30] days of purchase,
              provided the product is unopened and in its original packaging. To
              initiate a return, please contact us at [contact information]
            </li>
            <li>
              Refunds: Once your return is processed, a refund will be issued to
              the original payment method. Please note that shipping fees are
              non-refundable.
            </li>
            <li>
              Damaged Goods: If you receive a damaged or defective item, please
              contact us within [7] days for a replacement or refund.
            </li>
          </ul>
        </p>

        <h6 className="short-headings">7. Account and Security</h6>
        <p className="sub-writings-terms">
          <ul>
            <li>
              {" "}
              Account Creation: You may need to create an account to place
              orders, track purchases, and receive promotional offers. You are
              responsible for maintaining the confidentiality of your account
              information.
            </li>
            <li>
              Security: We take security seriously and use industry-standard
              encryption to protect your personal information. However, you are
              responsible for maintaining the security of your account login
              details.
            </li>
          </ul>
        </p>

        <h6 className="short-headings">8. Intellectual Property</h6>
        <p className="sub-writings-terms">
          <ul>
            <li>
              {" "}
              Ownership: All content on the Site, including logos, trademarks,
              text, images, and graphics, is owned or licensed by Bhakshanangal
              and is protected by intellectual property laws.
            </li>
            <li>
              Use of Content: You may not use any content from the Site without
              prior written consent from Bhakshanangal.
            </li>
          </ul>
        </p>

        <h6 className="short-headings">9. Limitation of Liability</h6>
        <p className="sub-writings-terms">
          <ul>
            <li>
              {" "}
              No Liability for Damages: To the fullest extent permitted by law,
              Bhakshanangal will not be liable for any indirect, incidental,
              special, or consequential damages arising from your use of this
              website or our products, including but not limited to loss of
              profits, data, or goodwill.
            </li>
            <li>
              Liability Cap: Our total liability to you for any claim related to
              these Terms and Conditions will not exceed the amount you paid for
              the product that is the subject of the claim.
            </li>
          </ul>
        </p>

        <h6 className="short-headings">10. Indemnity</h6>
        <p className="sub-writings-terms">
          You agree to indemnify and hold harmless Bhakshanangal, its affiliates,
          employees, and agents from any claim, demand, loss, or damages,
          including legal fees, arising out of your use of the Site or violation
          of these Terms and Conditions.
        </p>

        <h6 className="short-headings">11. Privacy Policy</h6>
        <p className="sub-writings-terms">
          Your use of our website is also governed by our [Privacy Policy],
          which explains how we collect, use, and protect your personal
          information. By using our services, you consent to the collection and
          use of your data in accordance with our Privacy Policy
        </p>

        <h6 className="short-headings">12. Modifications to Terms</h6>
        <p className="sub-writings-terms">
          We reserve the right to change, update, or modify these Terms and
          Conditions at any time without prior notice. Any changes will be
          posted on this page, and the "Last Updated" date will be revised
          accordingly. Your continued use of the Site after any changes
          constitutes your acceptance of the modified Terms.
        </p>

        <h6 className="short-headings">13. Governing Law</h6>
        <p className="sub-writings-terms">
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of [Your Jurisdiction], without regard to its
          conflict of law principles.
        </p>

        <h6 className="short-headings">14. Dispute Resolution</h6>
        <p className="sub-writings-terms">
          Any disputes arising from these Terms and Conditions shall be resolved
          through binding arbitration, rather than in court, unless prohibited
          by law. If you are located in a jurisdiction where arbitration is not
          allowed, you may bring a claim in the courts of [Your Jurisdiction].
        </p>

        <h6 className="short-headings">15. Contact Information</h6>
        <p className="sub-writings-terms" >
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at:
          <br />
          <div style={{fontSize:"16px",fontWeight:"bolder"}} >
          Bhakshanangal
           <br />
           https://www.bhakshanangal.com
          </div>
        </p>
      </div>
    </>
  );
}

export default Terms;
