import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home/home/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import SignIn from "./pages/Home/Login/Login";
import Register from "./pages/Home/register/Register";
import Products from "./pages/product/Product";
// import Category from "./pages/cateogry/Category";
import Wishlist from "./pages/wishlist/Wishlist";
import Whatsapp from "./components/whatsapp/Whatsapp";
import ViewProduct from "./pages/ViewProducts/ViewProduct";
import Verify from "./pages/Home/verification/Verification";
import BuyNow from "./pages/Buynow/Buy";
import Privacy from "./pages/Quicklinks/Privacy/Privacy";
import Terms from "./pages/Quicklinks/Terms/Terms";
import Frequently from "./pages/Quicklinks/FAQ/Faq";
import About from "./pages/Quicklinks/About/About"
import Contact from "./pages/Quicklinks/Contact/Contact"
import Otp from "./pages/Home/Otp/Otp";
import ChangePassword from "./pages/Home/ChangePassword/Change";
import Cart from "./pages/Addtocart/Cart";
import './i18n'; 
import Previous from "./components/previous/Previous"




function Layout() {
  const location = useLocation();
  const currentPath = location.pathname;

  // Check if the current path is not "/login" or "/register"
  const showHeaderFooter = currentPath !== "/login" && currentPath !== "/register";

  return (
    <div className="app-container">
      {/* Show Header and Footer only if the current path is not "/login" or "/register" */}
      {showHeaderFooter && <Header />}
      
      {/* Main content of the page */}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/products" element={<Products />} />
          {/* <Route path="/category" element={<Category />} /> */}
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/viewproduct" element={<ViewProduct />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/buynow" element={<BuyNow />} />
          <Route path="/privacy&policy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/faq" element={<Frequently />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/addtocart" element={<Cart />} />         
        </Routes>
        <Whatsapp />
        <Previous/>
      </div>

      {/* Footer will be at the bottom of the page */}
      {showHeaderFooter && <Footer />}
    </div>
  );
}

export default function AppWrapper() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}
