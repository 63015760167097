import React from "react";
import "./about.css";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function About() {
  const { t } = useTranslation();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  return (
    <>
      <div className="about">
        <h2 className="section-heading" style={{direction}}
                        >
                         {t('WELCOME TO BHAKSHANANGAL !')}</h2>
        <hr />
        <h4 className="sub-headings">Our Mission</h4>
        <p>
          Our mission is to offer a diverse range of snacks and food products
          that cater to every palate. We are committed to ensuring that every
          product we sell meets our high-quality standards, freshness, and
          taste. Whether you're craving something sweet, salty, spicy, or
          savoury, Bhakshanangal has something for everyone.
        </p>

        <h4 className="sub-headings">Our Vision</h4>
        <p className="short-details">
          We envision Bhakshanangal as the go-to destination for snack lovers
          and food enthusiasts. By continually expanding our product range and
          maintaining our commitment to excellence, we aim to become a household
          name synonymous with delicious and high-quality food products.
        </p>
        
        <h4 className="sub-headings">What we Offer</h4>
        <div className="offer-section">
          <p className="short-details">
            <b>Wide Selection:</b> From traditional snacks to innovative new
            flavours, Bhakshanangal offers a variety of products to satisfy your
            cravings. Our catalogue includes chips, nuts, sweets, beverages, and
            much more.
          </p>
          <p className="short-details">
            <b>Quality Assurance:</b> We partner with reputable producers who
            use the finest ingredients and adhere to strict quality control
            measures. This ensures that every product you purchase from Bhakshanangal is
            fresh, delicious, and safe to consume.
          </p>
          <p className="short-details">
            <b>Convenience:</b> Enjoy the convenience of shopping for your
            favourite snacks and food products from the comfort of your home.
            Our user-friendly website and efficient delivery service make it easy
            to get what you want when you want it.
          </p>
        </div>

        <h4 className="sub-headings">Why Choose Us?</h4>
        <div className="choose-us">
          <p className="short-details">
            <b>Premium Quality:</b> We are dedicated to providing only the best
            snacks and food products, with a focus on quality and taste.
          </p>
          <p className="short-details">
            <b>Curated Selection:</b> Our team of food enthusiasts handpicks every
            product to ensure it meets our standards of excellence.
          </p>
          <p className="short-details">
            <b>Customer Satisfaction:</b> Your satisfaction is our top priority.
            We strive to provide exceptional service and a seamless shopping
            experience.
          </p>
          <p className="short-details">
            <b>Affordable Prices:</b> Everyone should have access to great food at
            reasonable prices. Enjoy competitive pricing on all our products.
          </p>
        </div>

        <h4 className="sub-headings">Our Story</h4>
        <p>
          Bhakshanangal was founded by a group of food lovers who wanted to
          share their passion for great-tasting snacks and food products with
          the world. Frustrated by the lack of online quality options, we
          created a platform that offers only the best.
          <br />
          Today, Bhakshanangal has become a trusted name for snack enthusiasts
          and foodies alike. We take pride in our carefully curated selection
          and our commitment to customer satisfaction. Every product in our
          store is chosen carefully, ensuring you get the best whenever you shop
          with us.
        </p>

        <h4 className="sub-headings">Join Us</h4>
        <p>
          We invite you to explore our wide range of snacks and food products
          and discover new favourites. Whether you're looking for a quick snack,
          a gift for a loved one, or ingredients for your next culinary
          adventure, Bhakshanangal is here to serve you.
          <br />
          Thank you for choosing Bhakshanangal. Happy snacking!
        </p>
      </div>
    </>
  );
}

export default About;
