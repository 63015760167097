import React, { useState } from "react";
import "./contact.css";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Enquiry submitted!");
  };

  return (
    <>
      <div className="contact">
        <h2 className="section-heading">Contact Us</h2>
        <hr />
        <p className="contact-subwriting">Have questions, feedback, or need assistance? We’re here to help! Reach out to us, and we’ll get back to you as soon as possible.</p>
        <Container className="my-5">
          <Row>
            {/* Left Column for Contact Information */}
            <Col xs={12} md={6} className="contact-details">
              <div className="contact-info">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <h5>Contact Us</h5>
                <p>We are available 24/7, 7 days a week</p>
                <p>Phone: +91-947533051</p>
                <p>Phone: +971-524707511</p>
              </div>
<hr />
              <div className="contact-info mt-4">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <h5>Email Us</h5>
                <p>Fill out our form and we will contact you within 24 hours.</p>
                <p>Email: customer@exclusive.com</p>
                <p>Email: support@exclusive.com</p>
              </div>
            </Col>

            {/* Right Column for Form */}
            <Col xs={12} md={6}>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formMessage" className="mt-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Type your message here"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className=" enquiry-button mt-4 w-100">
                  Enquire Now
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Contact;
