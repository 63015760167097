import React, { useState, useEffect } from "react";
import "./wishlist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function Wishlist() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [userId, setUserId] = useState(null);
  const [userApiKey, setUserApiKey] = useState(null);
  const { t } = useTranslation();
  const lan =localStorage.getItem('i18nextLng');

  // Retrieve userId and apiKey from localStorage
  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    const apiKey = localStorage.getItem("user_api_key");

    console.log("Retrieved userId:", userId); // Debug log
    console.log("Retrieved apiKey:", apiKey); // Debug log

    if (userId) setUserId(userId);
    if (apiKey) setUserApiKey(apiKey);
  }, []);

  // Fetch wishlist data
  useEffect(() => {
    const fetchWishlistData = async (language = localStorage.getItem('i18nextLng')) => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/wishlist/list",
          {language}, // Empty request body unless required
          {
            headers: {
              user_id: userId,
              api_key: userApiKey,
            },
          }
        );

        console.log("API Response:", response.data); // Debug log
        if (response.data.result) {
          setProducts(response.data.list);
        } else {
          setError(response.data.message || "Failed to fetch wishlist data");
        }
      } catch (error) {
        console.error("Error fetching wishlist data:", error.response?.data || error.message);
        setError(error.response?.data?.message || "Error fetching wishlist data");
      } finally {
        setLoading(false);
      }
    };

    fetchWishlistData();
  }, [userId, userApiKey]);

  // Handle product removal from wishlist (removes from backend and state)
  // const handleRemoveProduct = async (productId) => {
  //   try {
  //     // Send a request to the backend to remove the product from the wishlist
  //     const response = await axios.post(
  //       "https://lunarsenterprises.com:3000/bhakshanangal/wishlist/remove", 
  //       { product_id: productId },
  //       {
  //         headers: {
  //           user_id: userId,
  //           api_key: userApiKey,
  //         }
  //       }
  //     );

  //     if (response.data.result) {
  //       // Remove the product from the state if the removal was successful
  //       setProducts(prevProducts => prevProducts.filter(product => product.product_id !== productId));
  //     } else {
  //       setError(response.data.message || "Failed to remove product");
  //     }
  //   } catch (error) {
  //     console.error("Error removing product from wishlist:", error.response?.data || error.message);
  //     setError(error.response?.data?.message || "Error removing product from wishlist");
  //   }
  // };

  const handleDivClick = (productId) => {
    console.log(`Product ${productId} clicked`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  const handleAddToCart = async (product_id, currentCartStatus,language = localStorage.getItem('i18nextLng')) => {
    try {
      // Ensure userId and userApiKey are available
      if (!userId || !userApiKey) {
        console.error("User is not authenticated");
        alert("Please log in to manage your cart.");
        return;
      }
  
      // Determine the new cart status
      const newCartStatus = currentCartStatus ? 0 : 1;
  
      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/addcart",
        {
  
          cart: newCartStatus, // Toggle cart status
          product_id
          
        },
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
          },
        }
      );
      console.log(response.data, "Response from server");
  
      if (response.data.result) {
        if (newCartStatus) {
          // Remove the product from the state to hide it from the page
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product.product_id !== product_id)
          );
          alert("Product added to cart and removed from the page.");
        } else {
          // Update the product's `isInCart` status if removing from the cart
          setProducts((prevProducts) =>
            prevProducts.map((product) =>
              product.product_id === product_id
                ? { ...product, isInCart: newCartStatus }
                : product
            )
          );
          alert("Product removed from cart.");
        }
      } else {
        console.error("Failed to update cart:", response.data.message);
        alert(response.data.message || "Failed to update cart.");
      }
    } catch (error) {
      console.error("Error updating cart:", error);
      alert("An error occurred while updating the cart. Please try again.");
    }
  };
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';

  

  return (
    <div>
      <section className="ezy__epgrid5 light">
        <div className="container">
          {/* Row start */}
          <div className="row ezy__epgrid5-row text-center justify-content-xl-center mt-2">
            {/* Map through products */}
            {products.map((product) => (
              <div
                key={product.product_id}
                className="col-md-6 col-lg-4 col-xl-3 px-2 my-3"
                onClick={() => handleDivClick(product.product_id)}
              >
                <div className="card ezy__epgrid5-card border-0 p-2">
                  <div className="card-body ezy__epgrid5-card-img position-relative h-100">
                    {/* Wishlist Icon */}
                    {/* <a href="#!" className="ezy__epgrid5-fav-icon" onClick={(e) => {
                      e.stopPropagation(); 
                      handleRemoveProduct(product.product_id);
                    }}> */}
                      {/* <FontAwesomeIcon className="close-button" icon={faSquareXmark} /> */}
                    {/* </a> */}
                    <div className="ezy__epgrid5-card-img-wrapper">
                      <a href="#!">
                        <img
                          src={`https://lunarsenterprises.com:3000/${product.image_file}`}
                          alt={product.product_name}
                          className="ezy__epgrid5-card-img-full"
                        />
                      </a>
                    </div>
                  </div>

                  <div>
                    <h5 className="ezy__epgrid5-title">{product.product_name}</h5>
                    <h5 className="ezy__epgrid5-price my-2">Rs.{product.price}.00</h5>
                    <p className="ezy__epgrid5-quantity">
                      {product.quantity} {product.product_unit}
                    </p>
                    <div className="ezy__epgrid5-details d-flex justify-content-between align-items-center">
                      <Button
                        href="/cart"
                        variant="primary"
                        className="buy-now w-100"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default link behavior
                          handleAddToCart(product.product_id, product.isInCart);
                        }}
                        style={{direction}}
                        >
                         {t('MOVE TO BAG')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Wishlist;
