import React from "react";
import "./previous.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Previous() {
  const navigate = useNavigate(); // Hook to navigate between pages

  const handlePreviousClick = () => {
    navigate(-1); // Go to the previous page
  };

  return (
    <div className="previous-button" onClick={handlePreviousClick}>
      <FontAwesomeIcon
        icon={faCircleChevronLeft}
        style={{ color: "#090a0b", cursor: "pointer" }}
      />
    </div>
  );
}

export default Previous;
