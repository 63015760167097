import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./buy.css";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import gift from "./../../Assests/gift.png"

function Buy() {
  // const [cartItems, setCartItems] = useState([]);
  // const [totalPrice, setTotalPrice] = useState(0);
  const { t } = useTranslation();
  const lan = localStorage.getItem("i18nextLng");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    phone_number: "",
    // alt_phone_number: "",
    pincode: "",
    state: "",
    city: "",
    building_name: "",
    area_name: "",
    landmark: "",
  });
  const location = useLocation();
  const { products, totalPrice } = location.state || {};

  console.log(products, "cartItems");
  console.log(totalPrice, "totsl");

  // Now you can render the products and total price

  const { productlist, productName, price, quantity, product_unit } =
    location.state || {};

  console.log(productlist, productName, price, quantity, product_unit);

  // Ensure productlist is an array
  const safeProductlist = Array.isArray(productlist) ? productlist : [];
  const safecartItems = Array.isArray(products) ? products : [];

  // Create a combined cart items array
  const combinedCartItems = [
    ...safecartItems.map((product) => ({
      name: product?.productName,
      quantity: product?.quantity,
      price: product?.price,
    })),
    ...safeProductlist.map((product) => ({
      name: product?.productName,
      quantity: product?.quantity,
      price: product?.price,
    })),
    ...(productName && price && quantity
      ? [
          {
            name: productName,
            quantity: quantity,
            price: price,
          },
        ]
      : []),
  ];
  console.log(
    combinedCartItems,
    "combinedCartItemssssssssssssssssssssssssssssssssssssssss"
  );

  const [addresses, setAddresses] = useState([]);
  // const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [giftmessage,setGiftmessage]=useState("");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);

  
    
    
    // console.log(selectedOption,"hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
  };

  // useEffect(() => {
  //   const cartData = JSON.parse(localStorage.getItem("cart_items")) || [];
  //   const price = parseFloat(localStorage.getItem("total_price")) || 0;

  //   setCartItems(cartData);
  //   setTotalPrice(price);
  // }, []);

  const handlegiftmessage = (event) => {
    setGiftmessage(event.target.value);
  };
  console.log(giftmessage, "hhhhhhhhhhhhhhhhhhhhhhh");


  
  const fetchAddresses = async (
    language = localStorage.getItem("i18nextLng")
  ) => {
    try {
      const userId = localStorage.getItem("user_id");
      const apiKey = localStorage.getItem("user_api_key");

      const response = await axios.get(
        "https://lunarsenterprises.com:3000/bhakshanangal/addresslist",

        {
          headers: {
            "Content-Type": "application/json",
            api_key: apiKey,
            user_id: userId,
          },
        }
      );
      console.log(response.data.list, "dddddd");

      if (response.data.result) {
        setAddresses(response.data.list || []);
      } else {
        console.error("Error fetching addresses:", response.data.message);
        alert("Failed to load addresses.");
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
      alert("Failed to load addresses. Please try again.");
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);
  console.log(addresses, "gggggggggggggggggggggggggggggggggggggggg");

  const handleSelect = async (event) => {
    setSelectedAddressId(event.target.value);

    if (selectedAddressId) {
      try {
        const userId = localStorage.getItem("user_id");
        const apiKey = localStorage.getItem("user_api_key");

        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/setaddress",
          { address_id: selectedAddressId }, // Body
          {
            headers: {
              "Content-Type": "application/json",
              user_id: userId, // Replace `userId` with the actual user ID
              api_key: apiKey, // Replace `apiKey` with the actual API key
            },
          }
        );
        console.log(response, "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
        if (response.status === 200) {
          alert("Address selected successfully!");
          console.log("Response:", response.data);
        } else {
          console.error("Failed to set address", response.data);
        }
      } catch (error) {
        console.error("Error occurred while setting address:", error);
        alert("Failed to select address. Please try again.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const userId = localStorage.getItem("user_id");
      const apiKey = localStorage.getItem("user_api_key");

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/addaddress",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            api_key: apiKey,
            user_id: userId,
          },
        }
      );
      console.log("Response:", response.data);
      alert("Address added successfully!");
      setFormData({
        full_name: "",
        phone_number: "",
        // alt_phone_number: "",
        pincode: "",
        state: "",
        city: "",
        building_name: "",
        area_name: "",
        landmark: "",
      });
      setShowModal(false);
    } catch (error) {
      console.error("Error adding address:", error);
      alert("Failed to add address. Please try again.");
    }
  };
  const direction = i18n.language === "ar" ? "rtl" : "ltr";

  console.log(productlist, "aaaaaaaaaaaaaaaaaaaaaaa");

  const handleCheckout = async () => {
    const productDetails = [
      {
        // product_name: productName,
        // product_price: price,
        product_id: productlist || products.productId,
        order_quantity: quantity || products.quantity,
        order_unit: product_unit || products.unit,
      },
    ];

    console.log(productDetails, "Product details for checkout");

    const requestData = {
      payment_method: selectedOption, // Payment method (e.g., "cod")
      product_details: productDetails, // List of product details
    };

    try {
      const userId = localStorage.getItem("user_id");
      const apiKey = localStorage.getItem("user_api_key");

      // Check if user_id and user_api_key are available in localStorage
      if (!userId || !apiKey) {
        console.error("User authentication details are missing");
        return; // Exit the function if the user is not authenticated
      }

      console.log(selectedAddressId,"selectedAddressId");
      
      if(!selectedAddressId){
       alert("please select a address");
        return; // Exit the function if the user is not authenticated
      }


      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/add-order",
        {
          product_details: productDetails,
          payment_method: selectedOption,
          address_id: selectedAddressId,
          amount: totalPrice ||price,
          gift_card:giftmessage
        },
        {
          headers: {
            "Content-Type": "application/json",
            "user_id": userId, // Pass user_id in headers
            "api_key": apiKey, // Pass user_api_key in headers
          }
        }
      );
      console.log(response, "bbbbbbbbbbbbbbbbbbbbbbbb");

      const data = response

      if (response.data.result===true) {
        if(selectedOption === 'Online Payment'){
          window.location.href = response.data.paymentLinkUrl;

        }else{
        alert(`Order placed successfully`);
          
        }

        console.log("Order placed successfully", data);
        // Handle success (e.g., show a confirmation message or redirect)
      } else {
        console.error("Error placing order", data);
        // Handle error (e.g., show an error message to the user)
        alert(`Error: ${data.message || "Unknown error occurred"}`);
      }
    } catch (error) {
      console.error("Error making API request:", error);
      // Handle API call failure (e.g., show an error message to the user)
      alert("There was an issue with the request. Please try again later.");
    }
  };

 
  return (
    <>
      <div
        className="delivering-address"
        onClick={handleShowModal}
        style={{ cursor: "pointer" }}
      >
        <h6 className="delivering-to" style={{ direction }}>
          <FontAwesomeIcon icon={faLocationDot} className="me-2" />
          {t("DELIVERY TO")}
        </h6>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ direction }}>
            {" "}
            {t("ADD NEW ADDRESS")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="fullName">
              <Form.Label style={{ direction }}> {t("FULL NAME")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("FULL NAME")}
                name="full_name"
                value={formData.full_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phoneNumber">
              <Form.Label style={{ direction }}>{t("PHONE NUMBER")}</Form.Label>
              <Form.Control
                style={{ direction }}
                type="text"
                placeholder={t("PHONE NUMBER")}
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="state">
              <Form.Label style={{ direction }}> {t("STATE")}</Form.Label>
              <Form.Control
                style={{ direction }}
                type="text"
                placeholder={t("STATE")}
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="district">
              <Form.Label style={{ direction }}> {t("DISTRICT")}</Form.Label>
              <Form.Control
                style={{ direction }}
                type="text"
                placeholder={t("DISTRICT")}
                name="district"
                value={formData.state}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="city">
              <Form.Label style={{ direction }}>{t("CITY")} </Form.Label>
              <Form.Control
                style={{ direction }}
                type="text"
                placeholder={t("CITY")}
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="buildingName">
              <Form.Label style={{ direction }}>
                {t("BUILDING NAME")}
              </Form.Label>
              <Form.Control
                style={{ direction }}
                type="text"
                placeholder={t("BUILDING NAME")}
                name="building_name"
                value={formData.building_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="areaName">
              <Form.Label style={{ direction }}>{t("AREA NAME")}</Form.Label>
              <Form.Control
                type="text"
                style={{ direction }}
                placeholder={t("AREA NAME")}
                name="area_name"
                value={formData.area_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="landmark">
              <Form.Label style={{ direction }}> {t("LANDMARK")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("LANDMARK")}
                name="landmark"
                value={formData.landmark}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="pincode">
              <Form.Label style={{ direction }}> {t("PINCODE")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("PINCODE")}
                name="pincode"
                value={formData.pincode}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Button
              type="submit"
              variant="success"
              className="w-100  mb-3"
              style={{ direction }}
            >
              {" "}
              {t("SAVE ADDRESS")}
            </Button>
            <Button
              variant="secondary"
              className="w-100"
              onClick={handleCloseModal}
              style={{ direction }}
            >
              {" "}
              {t("CANCEL")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Container className="py-4">
        <Row>
          <Col md={7} className="mb-4">
            <Card className="p-4 shadow-sm">
              <div>
                <Form.Group
                  className="mb-3"
                  controlId="GiftCardMessage"
                  style={{ fontWeight: "bold" }}
                >
                  <Form.Label style={{ direction }}>
                    {t("Gift Card message")}
                    <img className="gift-img" src={gift} alt="" />
                  </Form.Label>
                  <Form.Control
                    style={{ direction }}
                    type="text"
                    placeholder={t("Enter Gift Card message")}
                    name="giftCardAmount"
                    value={giftmessage}
                    onChange={handlegiftmessage}
                    required
                  />
                </Form.Group>
              </div>
              <Form>
                <h4 style={{ direction }}> {t("SHIPPPING ADDRESS")}</h4>
                <Form.Group>
                  <Form.Label style={{ direction }}>
                    {t("SELECT A SAVED ADDRESS")}
                  </Form.Label>
                  <Form.Control
                    className="form-choose-address"
                    as="select"
                    // value={}
                    onChange={handleSelect}
                  >
                    <option value="" style={{ direction }}>
                      {t("CHOOSE AN ADDRESS")}
                    </option>
                    {addresses.map((addr) => (
                      <option key={addr.address_id} value={addr.address_id}>
                        {addr.address_fullname} - {addr.address_building_name},{" "}
                        {addr.address_area_name}, {addr.address_city},{" "}
                        {addr.address_state} - {addr.address_pincode}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {/* {selectedAddress && (
                  <div
                    style={{
                      marginTop: "20px",
                      padding: "10px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <h5>Selected Address</h5>
                    <p>
                      <strong>Name:</strong>{" "}
                      {
                        addresses.find(
                          (addr) => addr.address_id === selectedAddress
                        )?.address_fullname
                      }
                    </p>
                    <p>
                      <strong>Building:</strong>{" "}
                      {
                        addresses.find(
                          (addr) => addr.address_id === selectedAddress
                        )?.address_building_name
                      }
                    </p>
                    <p>
                      <strong>Area:</strong>{" "}
                      {
                        addresses.find(
                          (addr) => addr.address_id === selectedAddress
                        )?.address_area_name
                      }
                    </p>
                    <p>
                      <strong>City:</strong>{" "}
                      {
                        addresses.find(
                          (addr) => addr.address_id === selectedAddress
                        )?.address_city
                      }
                    </p>
                    <p>
                      <strong>State:</strong>{" "}
                      {
                        addresses.find(
                          (addr) => addr.address_id === selectedAddress
                        )?.address_state
                      }
                    </p>
                    <p>
                      <strong>Pincode:</strong>{" "}
                      {
                        addresses.find(
                          (addr) => addr.address_id === selectedAddress
                        )?.address_pincode
                      }
                    </p>
                  </div>
                )} */}
              </Form>
            </Card>
          </Col>

          <Col md={5}>
            <Card className="p-4 mb-4 shadow-sm">
              <div className="mb-3">
                <h6 style={{ direction }}> {t("SELECT PAYMENT OPTION")}</h6>
                <div className="form-check">
                  <input
                    type="radio"
                    id="Online Payment"
                    name="paymentOption"
                    value="Online Payment"
                    checked={selectedOption === "Online Payment"}
                    onChange={handleRadioChange}
                    className="form-check-input"
                  />
                  <label
                    htmlFor="Online Payment"
                    className="form-check-label"
                    style={{ direction }}
                  >
                    {" "}
                    {t("ONLINE PAYMENT")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="option2"
                    name="paymentOption"
                    value="Cash on Delivery"
                    checked={selectedOption === "Cash on Delivery"}
                    onChange={handleRadioChange}
                    className="form-check-input"
                  />
                  <label
                    htmlFor="Cash on Delivery"
                    className="form-check-label"
                    style={{ direction }}
                  >
                    {" "}
                    {t("CASH ON DELIVERY")}
                  </label>
                </div>

                <div className="mt-3">
                  <h6 style={{ direction }}>
                    {t("PAYMENT MODE")}:{" "}
                    <span className="text-muted">{selectedOption}</span>
                  </h6>
                </div>
                <h4 className="mb-3" style={{ direction }}>
                  {t("ORDER SUMMARY")}
                </h4>

                <ul className="list-unstyled">
                  {combinedCartItems.map((item, index) => (
                    <li
                      key={index}
                      className="d-flex justify-content-between mb-2"
                    >
                      <span>
                        {item.name} x {item.quantity}
                      </span>
                      <span>₹{item.price}</span>
                    </li>
                  ))}
                  <li
                    className="d-flex justify-content-between border-top mt-3 pt-3"
                    style={{ direction }}
                  >
                    {" "}
                    {t("TOTAL")}
                    <strong>
                      RS
                      {combinedCartItems.reduce(
                        (total, item) => total + item.price * item.quantity,
                        0
                      )}
                    </strong>
                  </li>
                </ul>

                {/* Checkout Button */}
                <Button
                  variant="success"
                  className="w-100 py-2 mb-3"
                  disabled={!selectedOption} // Button is disabled if no payment option is selected
                  style={{ direction }}
                  onClick={handleCheckout}
                >
                  {" "}
                  {t("CHECKOUT")}
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Buy;
