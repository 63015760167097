import React from "react";
import "./privacy.css";

function Privacy() {
  return (
    <>
      <div className="privacy-policy">
        <h5 className="headline-terms">PRIVACY AND POLICY</h5>
        <p className="sub-writings-terms mt-5">
        <b>Effective date: May 6, 2024</b>
        <br />
          At Bhakshanangal ("we," "us," or "our"), we value the privacy of our
          customers and visitors to our website https://www.bhakshanangal.com. This
          Privacy Policy outlines how we collect, use, disclose, and protect
          your personal information when you use our website and make purchases.
          By accessing or using our website, you agree to the practices outlined
          in this policy.
        </p>

        <h6 className="short-headings">1. Information We Collect</h6>
        <p className="sub-writings-terms">
          We collect two types of information from you when you visit our
          website:
        </p>
        <h6 className="short-headings">a. Personal Information:</h6>
        <p className="sub-writings-terms">
          When you make a purchase, create an account, or interact with us in
          other ways, we may collect the following personal details:
        </p>
        <ul>
          <li> Name</li>
          <li> Email address</li>
          <li>Billing and shipping addresses</li>
          <li> Phone number</li>
          <li>
            Payment information (credit/debit card details, processed via secure
            third-party payment processors)
          </li>
          <li>Order history</li>
          <li>Account login details</li>
        </ul>

        <h6 className="short-headings">b. Non-Personal Information:</h6>
        <p className="sub-writings-terms">
          We also collect non-personal information automatically as you use our
          website, such as:
        </p>
        <ul>
          <li>IP address</li>
          <li>Browser type</li>
          <li>Device information</li>
          <li>Location data</li>
          <li>Cookies and usage data (see "Cookies" section below)</li>
        </ul>

        <h6 className="short-headings">2. How We Use Your Information</h6>
        <p className="sub-writings-terms">
          We use your personal and non-personal information for the following
          purposes:
        </p>
        <ul>
          <li>
            To process your orders: We use your information to process your
            transactions, deliver your products, and provide customer service.
          </li>
          <li>
            To personalize your shopping experience: We may use your preferences
            and browsing history to tailor recommendations and improve your
            experience on our website.
          </li>
          <li>
            To communicate with you: We send order confirmations, shipping
            updates, marketing promotions (with your consent), and customer
            service-related communications.
          </li>
          <li>
            To improve our website and services: We use non-personal data to
            analyze user behavior and enhance our site’s functionality.
          </li>
          <li>
            For legal compliance: We may use your data to comply with legal
            obligations and protect our rights or the rights of others.
          </li>
        </ul>

        <h6 className="short-headings">3. Sharing Your Information</h6>
        <p className="sub-writings-terms">
          We use industry-standard security measures to protect your personal
          information from unauthorized access, alteration, or disclosure. This
          includes encryption technologies and secure servers. However, please
          note that no method of transmission over the internet or electronic
          storage is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h6 className="short-headings">4. Data Security</h6>
        <p className="sub-writings-terms">
          We respect your privacy and do not sell or rent your personal
          information to third parties. However, we may share your information
          with third parties in the following cases:
        </p>

        <h6 className="short-headings">Refund and Return Policy</h6>
        <p className="sub-writings-terms">
          At Bhakshanangal, we are committed to providing our customers with the
          finest taste of Kerala. We understand that sometimes things don’t go as
          planned, and we want to ensure you're satisfied with your purchase.
          Please review our refund and return policy below:
        </p>

        <h6 className="short-headings">1. Returns</h6>
        <p className="sub-writings-terms">
          Due to the nature of our products, we do not accept returns on opened
          or used products. This policy is in place to ensure product
          quality and safety for all of our customers.
        </p>

        <h6 className="short-headings">
          Returns may be accepted under the following conditions:
        </h6>
        <ul>
          <li>
            The product is unopened and in its original, sealed packaging.
          </li>
          <li>You received the wrong product or a defective product.</li>
        </ul>
        <p className="sub-writings-terms">
          To initiate a return, please contact us within 7 days of receiving
          your order. You can reach us via [Email] or [Phone Number] with your
          order number and details about the issue. If your return is approved,
          we will provide instructions on how to return the product.
        </p>
        <p className="sub-writings-terms">
          Note: Customers are responsible for return shipping costs, unless the
          return is due to a defective product or our mistake (e.g., wrong item
          sent).
        </p>

        <h6 className="short-headings">2. Returns</h6>
        <p className="sub-writings-terms">
          Refunds are issued in the following circumstances:
        </p>
        <ul>
          <li>If the product is defective or damaged during shipping.</li>
          <li>If you received the wrong product.</li>
          <li>
            If the item has not been opened and is returned within 7 days of
            delivery.
          </li>
        </ul>

        <p className="sub-writings-terms">
          Refunds will be issued to the original method of payment once the
          returned product has been received and inspected. Please allow up to 7
          business days for your refund to appear in your account.
        </p>

        {/* <h6 className="short-headings">5. Shipping Policy</h6>
        <p className="sub-writings-terms">
          Orders are processed within 7-10 working days. Orders placed on
          weekends or holidays will be processed the next business day. High
          demand or holiday seasons may result in delays.
        </p>

        <h6 className="short-headings">6. Shipping Rates & Delivery Times</h6>
        <p className="sub-writings-terms">
          <b>Standard Shipping:</b> (Free Delivery), Estimated delivery in 7-10 working days.
          <br />
          <b>Expedited Shipping: </b>(Free Delivery), Estimated delivery in 7-10 business
          days. 
          <br />
          <b>Note:</b>  Delivery times are estimates and not guaranteed.
        </p>
        <br /> */}
      </div>
    </>
  );
}

export default Privacy;
