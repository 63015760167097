import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./footer.css";
import logo from "../../Assests/logo.png";
import flag from "../../Assests/Dubai.png";
import india from "../../Assests/India.png";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const direction = i18n.language === "ar" ? "rtl" : "ltr";

  return (
    <footer className="footer" dir={direction}>
      <Container>
        <Row className="mb-3">
          <Col md={2}>
            <img className="footer-logo" src={logo} alt="Company Logo" />
          </Col>

          <Col md={4}>
            <h5>{t("QUICK LINKS")}</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/privacy&policy" className="footer-icons">
                  {t("PRIVACY AND POLICY")}
                </a>
              </li>
              <li>
                <a href="/terms" className="footer-icons">
                  {t("TERMS AND USE")}
                </a>
              </li>
              <li>
                <a href="/faq" className="footer-icons">
                  {t("FAQ")}
                </a>
              </li>
              <li>
                <a href="/contact" className="footer-icons">
                  {t("CONTACT US")}
                </a>
              </li>
              <li>
                <a href="/about-us" className="footer-icons">
                  {t("ABOUT US")}
                </a>
              </li>
            </ul>
          </Col>

          <Col md={6}>
            <h5 className="mb-4">{t("KEEP IN TOUCH")}</h5>
            {/* Dubai Address */}
            <div className="contact-location mb-4">
              <div className="d-flex align-items-start">
                <img className="dubai-flag me-2" src={flag} alt="Dubai Flag" />
                <div>
                  <p className="mb-1">{t("BUILDING A2 IFZA, BUSINESS PARK,")}</p>
                  <p className="mb-1">{t("DUBAI SILICON OASIS, DUBAI")}</p>
                  <p className="mb-0">
                    <FontAwesomeIcon className="me-2" icon={faPhone} />
                    +971-524707511
                  </p>
                </div>
              </div>
            </div>

            {/* India Address */}
            <div className="contact-location">
              <div className="d-flex align-items-start">
                <img className="dubai-flag me-2" src={india} alt="India Flag" />
                <div>
                  <p className="mb-1">{t("MG CORPORATE CENTRE")}</p>
                  <p className="mb-1">
                    {t(
                      "NEAR IOC PUMP, ULLOOR JUNCTION, MCPO, TVM, KERALA 695011"
                    )}
                  </p>
                  <p className="mb-0">
                    <FontAwesomeIcon className="me-2" icon={faPhone} />
                    +919947533051
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* Social Media Links */}
        <div className="d-flex justify-content-end">
          <a
            href="https://www.facebook.com/profile.php?id=61553792413706"
            className="footer-goto-icons me-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="footer-socialmedia" icon={faFacebookF} />
          </a>
          <a
            href="https://www.instagram.com/bhakshanangal_foods/"
            className="footer-goto-icons"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className="footer-socialmedia" icon={faInstagram} />
          </a>
        </div>

        {/* Footer Bottom */}
        <Row className="pt-3 border-top">
          <Col md={12}>
            <p className="copy-right">
            {t(
                      "©copy; 2024 Bhakshanangal Foods Private Ltd. All Rights Reserved."
                    )}
              
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
